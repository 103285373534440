import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import RLDD from "react-list-drag-and-drop/lib/RLDD";

import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";

import "moment/locale/fr";
import "react-quill/dist/quill.snow.css"; // ES6

import SwiziConnector from "./connectors/SwiziConnector2";
import ResourceConnector from "./connectors/ResourceConnector";

import { ReactComponent as Calendar } from "./assets/calendar-1.svg";
import { ReactComponent as TimeClock } from "./assets/time-clock-circle.svg";
import { ReactComponent as AddContentBG } from "./assets/add-content.svg";
import { ReactComponent as AddCategoryBG } from "./assets/add-category.svg";
import { ReactComponent as Marker } from "./assets/style-two-pin-marker.svg";
import { ReactComponent as Edit } from "./assets/edit.svg";
import { ReactComponent as Delete } from "./assets/delete.svg";
import { ReactComponent as Back } from "./assets/close.svg";
import { ReactComponent as Add } from "./assets/add-circle.svg";
import { ReactComponent as Loading } from "./assets/loading.svg";
import { ReactComponent as Error } from "./assets/error.svg";
import { ReactComponent as Format } from "./assets/format.svg";
import { withTranslation } from "react-i18next";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-monokai";
//import "./lang/i18n";

import { withArrayTranslate } from "./lang/useArrayTranslate";

import "./SwiziNewsEditor.css";
import { getImageLightness } from "./utils/index";
import { html_beautify } from "js-beautify";
import { message } from "antd";
import { isEmpty, isNil } from "ramda";
const Quill = ReactQuill.Quill;
var Font = Quill.import("attributors/class/font");
Font.whitelist = ["helvetica", "arial", "verdana", "times-new-roman"];
Quill.register(Font, true);

class SwiziNewsEditor extends Component {
  constructor(props) {
    super(props);
    this.reactQuillRef = React.createRef();
    this.defaultState = {
      // component variables
      lang: "fr",
      brightness: 255,
      timeOffset: 15,
      formOperator: { label: "Avant", value: "+" },
      fromEnd: { label: "Début", value: false },
      showHTML: false,
      isUploading: false,
      wannaBack: false,
      appInitialized: false,
      loadingLabel: this.props.t("Get_details"),
      err: null,
      errTopics: null,
      errAction: null,
      section: null,
      resources: null,
      hasRM: false,
      stageSelected: null,
      roomSelected: null,
      placeName: {
        fr: "",
        en: "",
      },
      preview: "iPhoneXR",
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            // ["blockquote", "code-block"], // blocks
            // [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }], // lists
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            // [{ direction: "rtl" }], // text direction
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
            [{ color: [] }, { background: [] }], // dropdown with defaults
            // [{ font: ["helvetica", "arial", "verdana", "times-new-roman"] }]
            [{ align: [] }], // text align
            ["link", "clean", "image"], // remove formatting
          ],
          handlers: {
            image: () => {
              this.setState({ wantToAddPhoto: true, showLibrary: true });
            },
          },
        },
      },
      formats: [
        "header",
        "font",
        "background",
        "color",
        "code",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "script",
        "align",
        "direction",
        "link",
        "image",
        "code-block",
        "formula",
        "video",
      ],
      Swizi: null,
      showLibrary: false,
      selectedContent: null,
      // datas
      medias: null,
      groups: [],
      contents: null,

      // generic content variables
      hideFutureEvent: false,
      toBePushed: false,
      header: {
        fr: {
          id: null,
          url: "",
        },
        en: {
          id: null,
          url: "",
        },
      },
      groupsSelected: null,
      forceGroupsSelected: null,
      title: {
        fr: "",
        en: "",
      },
      subtitle: {
        fr: "",
        en: "",
      },
      content: {
        fr: "",
        en: "",
      },
      publication: new Date(),
      topics: null,
      event: null,
      showTopicCreation: false,
      topicToEdit: {
        fr: {
          label: null,
        },
        en: {
          label: null,
        },
        icon: null,
        id: null,
        order: -1,
      },
      eventToEdit: {
        fr: {
          label: null,
        },
        en: {
          label: null,
        },
        startDate: null,
        endDate: null,
      },
      needConfirmationToClose: false,
    };
  }
  state = {
    // component variables
    lang: "fr",
    showHTML: false,
    brightness: 255,
    formOperator: { label: "Avant", value: "+" },
    fromEnd: { label: "Début", value: false },
    isUploading: false,
    appInitialized: false,
    previewSelected: { value: 0, label: "iPhone X" },
    loadingLabel: this.props.t("Get_Content"),
    err: null,
    errAction: null,
    resources: null,
    hasRM: false,
    stageSelected: null,
    roomSelected: null,
    timeOffset: 15,
    placeName: {
      fr: "",
      en: "",
    },
    preview: 0,
    modules: {
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          // ["blockquote", "code-block"], // blocks
          // [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }], // lists
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          // [{ direction: "rtl" }], // text direction
          [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
          [{ color: [] }, { background: [] }], // dropdown with defaults
          // [{ font: ["helvetica", "arial", "verdana", "times-new-roman"] }], // font family
          [{ align: [] }], // text align
          ["clean", "image"], // remove formatting
        ],
        handlers: {
          image: () => {
            this.setState({ wantToAddPhoto: true, showLibrary: true });
          },
        },
      },
    },
    formats: [
      "header",
      "font",
      "background",
      "color",
      "code",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "script",
      "align",
      "direction",
      "link",
      "image",
      "code-block",
      "formula",
      "video",
    ],
    Swizi: null,
    showLibrary: false,
    selectedContent: null,
    // datas
    medias: null,
    groups: [],
    contents: null,

    // generic content variables
    hideFutureEvent: false,
    toBePushed: false,
    header: {
      fr: {
        id: null,
        url: "",
      },
      en: {
        id: null,
        url: "",
      },
    },
    groupsSelected: null,
    title: {
      fr: "",
      en: "",
    },
    subtitle: {
      fr: "",
      en: "",
    },
    content: {
      fr: "",
      en: "",
    },
    publication: new Date(),
    topics: null,
    event: null,
    format: "simple",
    reminders: [],
    tmpMedia: { src: null, name: null },
    tmpMediaCrop: {
      unit: "%",
      aspect: 2 / 1,
      width: 100,
    },
    tmpCroppedImage: null,
    showTopicCreation: false,
    topicToEdit: {
      fr: {
        label: null,
      },
      en: {
        label: null,
      },
      icon: null,
      id: null,
      order: -1,
    },
    eventToEdit: {
      fr: {
        label: null,
      },
      en: {
        label: null,
      },
      startDate: null,
      endDate: null,
    },
    needConfirmationToClose: false,
  };
  async componentDidMount() {
    moment.locale(this.props.i18n.language);
    this.initialize(this.props.isReminder);
  }
  initializeColors = () => {
    let root = document.documentElement;
    root.style.setProperty("--SwiziNewsEditor__Primary", this.props.primaryColor);
    root.style.setProperty("--SwiziNewsEditor__HeaderColor", "#023466");
    root.style.setProperty("--SwiziNewsEditor__ContentColor", "#023466");
    root.style.setProperty("--SwiziNewsEditor__Text", this.props.textColor);
  };
  initialize = async (isReminder = false) => {
    const { t } = this.props;
    this.setState(JSON.parse(JSON.stringify(this.defaultState)));
    this.initializeColors();
    if (!isReminder) {
      // this.swiziProdAppId = this.props.versioning
      //   ? this.props.versioning.prod.appId
      //   : this.props.appId
      this.swiziProdSectionId = this.props.swiziSectionId;
      // this.swiziProdSectionId = this.props.versioning
      //   ? this.props.versioning.prod.notifications[this.props.swiziSectionId]
      //   : this.props.swiziSectionId
      this.Swizi = new SwiziConnector({
        url: this.props.swiziApiUrl,
        apiKey: this.props.swiziApiKey,
        token: this.props.token,
        appId: this.props.appId,
      });

      // this.swiziTestAppId = this.props.versioning
      //   ? this.props.versioning.test.appId
      //   : null
      // this.swiziTestSectionId = this.props.versioning
      //   ? this.props.versioning.test.notifications[this.props.swiziSectionId]
      //   : null
      // this.SwiziTest = null

      // if (this.swiziTestAppId && this.swiziTestSectionId) {
      //   this.SwiziTest = new SwiziConnector({
      //     url: this.props.swiziApiUrl,
      //     apiKey: this.props.swiziApiKey,
      //     token: this.props.token,
      //     appId: this.swiziTestAppId,
      //   })
      // }

      let groups = [];
      let medias = null;
      let contents = null;
      let section = null;
      // let appColors = null;
      let forceGroupsSelected = null;
      try {
        section = await this.Swizi.getSection(this.swiziProdSectionId);
        this.setState({ section, loadingLabel: t("Get_Content") });
      } catch (err) {
        this.setState({ err: t("Get_Content_Error") });
      }

      if (section) {
        if (section.type === "NEWS") {
          try {
            const topics = await this.Swizi.getTopics(this.swiziProdSectionId);
            if (topics) {
              if (topics.options) {
                section.options = { ...(section.options || {}), ...topics.options };
              }
              if (topics.topics && topics.topics.length) {
                section.topics = topics.topics;
              }
              this.setState({ section });
            }
          } catch (err) {
            this.setState({ err: t("Get_Topics_Error") });
          }
        }

        try {
          contents = await this.Swizi.getContents(this.swiziProdSectionId, false);
          this.setState({ contents, loadingLabel: t("Get_Groups") });
        } catch (err) {
          this.setState({ err: t("Get_Groups_Error") });
        }
        if (contents) {
          try {
            groups = await this.Swizi.getGroups();
            if (this.props.groupId) {
              let selected = groups.find((g) => "" + g.id === "" + this.props.groupId);
              if (selected) {
                forceGroupsSelected = [selected];
                this.setState({ forceGroupsSelected: [selected] });
              }
            }
            this.setState({ groups, loadingLabel: t("Get_Media") });
          } catch (err) {
            this.setState({ err: t("Get_Media_Error") });
          }
          try {
            let tags = forceGroupsSelected ? forceGroupsSelected[0].label : "news-builder";
            medias = await this.Swizi.getMedias(tags);
            // appColors = await this.Swizi.getAppColors()
            this.setState({ medias });
          } catch (err) {
            this.setState({ err: t("Get_Media_Error") });
          }
          // Resource Manager
          if (
            medias &&
            this.props.rmApiKey &&
            this.props.rmApiKey.length &&
            this.props.rmSecretKey &&
            this.props.rmSecretKey.length &&
            this.props.rmApiUrl &&
            this.props.rmApiUrl.length &&
            this.props.format === "localized"
          ) {
            this.setState({ loadingLabel: t("Get_Resource") });
            this.ResourceManager = new ResourceConnector({ url: this.props.rmApiUrl });
            try {
              let isAuthent = await this.ResourceManager.authent(
                this.props.rmApiKey,
                this.props.rmSecretKey,
                this.props.token,
              );
              if (isAuthent) {
                const returnedFields = [
                  "id",
                  "uid",
                  "externalId",
                  "data.reference",
                  "data.nom_d_usage",
                  "title",
                  "srn",
                ];
                let resources = await this.ResourceManager.getResources(
                  this.props.i18n.language,
                  true,
                  returnedFields,
                );
                resources = resources[0].childrens[0].childrens;
                this.setState({ hasRM: true, resources, appInitialized: true, loadingLabel: "" });
              }
            } catch (err) {
              this.setState({ err: t("Get_Resource_Error") });
            }
          } else {
            this.setState({ appInitialized: true, loadingLabel: "" });
          }
        }
      }
    } else {
      const { allowedGroups } = this.props;
      this.setState({ loadingLabel: t("Get_Groups") });
      this.Swizi = new SwiziConnector({
        url: this.props.swiziApiUrl,
        apiKey: this.props.swiziApiKey,
        token: this.props.token,
        appId: this.swiziProdAppId,
      });
      let groups = await this.Swizi.getGroups(false);
      groups = groups.filter((g) => allowedGroups.find((ag) => ag === "" + g.id));
      this.setState({ loadingLabel: "Récupération des notifications" });
      this.setState({ groups, appInitialized: true, loadingLabel: "" });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    const { tArr } = this.props;
    if (!prevState.selectedContent && this.state.selectedContent) {
      if (typeof this.state.selectedContent === "string" && this.state.selectedContent === "new") {
        this.setState({
          showTopicCreation: false,
          stageSelected: null,
          roomSelected: null,
          header: {
            fr: {
              id: null,
              url: "",
            },
            en: {
              id: null,
              url: "",
            },
          },
          title: {
            fr: "",
            en: "",
          },
          subtitle: {
            fr: "",
            en: "",
          },
          content: {
            fr: "",
            en: "",
          },
          placeName: {
            fr: "",
            en: "",
          },
          publication: new Date(),
          hideFutureEvent: false,
          lang: "fr",
          showLibrary: false,
          groupsSelected: this.state.forceGroupsSelected ? this.state.forceGroupsSelected : null,
          toBePushed: false,
          topics: null,
          eventToEdit: JSON.parse(JSON.stringify(this.defaultState.eventToEdit)),
          needConfirmationToClose: false,
        });
      } else {
        if (!this.props.isReminder) {
          let currentContent = this.state.selectedContent;
          let publication = currentContent.hasOwnProperty("publication")
            ? new Date(currentContent.publication)
            : new Date(currentContent.creation);
          let title = {
            fr: currentContent.title.translations.find((t) => t.locale.code === "fr")?.translation
              ? currentContent.title.translations.find((t) => t.locale.code === "fr")?.translation
              : "",
            en: currentContent.title.translations.find((t) => t.locale.code === "en")?.translation
              ? currentContent.title.translations.find((t) => t.locale.code === "en")?.translation
              : "",
          };
          let subtitle = {
            fr:
              currentContent.hasOwnProperty("subtitle") &&
              currentContent.subtitle.translations.find((t) => t.locale.code === "fr").translation
                ? currentContent.subtitle.translations.find((t) => t.locale.code === "fr")
                    .translation
                : "",
            en:
              currentContent.hasOwnProperty("subtitle") &&
              currentContent.subtitle.translations.find((t) => t.locale.code === "en").translation
                ? currentContent.subtitle.translations.find((t) => t.locale.code === "en")
                    .translation
                : "",
          };
          let header =
            currentContent.header && currentContent.header.medias.length
              ? {
                  fr: {
                    id: currentContent?.header?.medias.find((m) => m.locale.code === "fr")
                      ? currentContent?.header?.medias.find((m) => m.locale.code === "fr")?.media
                          ?.id
                      : null,
                    url: currentContent.header.medias.find((m) => m.locale.code === "fr")
                      ? currentContent.header.medias.find((m) => m.locale.code === "fr")?.media?.url
                      : "",
                  },
                  en: {
                    id: currentContent.header.medias.find((m) => m.locale.code === "en")
                      ? currentContent.header.medias.find((m) => m.locale.code === "en")?.media?.id
                      : null,
                    url: currentContent.header.medias.find((m) => m.locale.code === "en")
                      ? currentContent.header.medias.find((m) => m.locale.code === "en")?.media?.url
                      : "",
                  },
                }
              : {
                  fr: {
                    id: null,
                    url: "",
                  },
                  en: {
                    id: null,
                    url: "",
                  },
                };
          let toBePushed = false;
          let hideFutureEvent = currentContent.hasOwnProperty("hideFutureEvent")
            ? currentContent.hideFutureEvent
            : false;

          let groupsSelected = null;
          if (currentContent.groups && currentContent.groups.length) {
            groupsSelected = currentContent.groups.map((g) => {
              return { label: g.label, value: g.id };
            });
          }
          let contentFR = currentContent.content.translations.find((t) => t.locale.code === "fr")
            ? currentContent.content.translations.find((t) => t.locale.code === "fr").translation
            : "";
          let contentEN = currentContent.content.translations.find((t) => t.locale.code === "en")
            ? currentContent.content.translations.find((t) => t.locale.code === "en").translation
            : "";
          let mediasStore = this.state.medias.content;
          let regex = /(<*([^>]+)>)/gi;

          let htmFRlTags = contentFR.match(regex);
          if (htmFRlTags && htmFRlTags.length) {
            for (let i = 0; i < htmFRlTags.length; i++) {
              if (htmFRlTags[i].indexOf("<image") !== -1) {
                let regexDataImageID = /data-imageid="([^"]*)"/;
                let dataID = htmFRlTags[i].match(regexDataImageID);
                dataID = parseInt(dataID[1], 0);
                let media = mediasStore.find((m) => m.id === dataID);
                if (media) {
                  let newTag = `<img src=${media.url}></img>`;
                  htmFRlTags[i] = newTag;
                }
              }
            }
          }
          let htmENTags = contentEN.match(regex);
          if (htmENTags && htmENTags.length) {
            for (let i = 0; i < htmENTags.length; i++) {
              if (htmENTags[i].indexOf("<image") !== -1) {
                let regexDataImageID = /data-imageid="([^"]*)"/;
                let dataID = htmENTags[i].match(regexDataImageID);
                dataID = parseInt(dataID[1], 0);
                let media = mediasStore.find((m) => m.id === dataID);
                if (media) {
                  let newTag = `<img src=${media.url}></img>`;
                  htmENTags[i] = newTag;
                }
              }
            }
          }
          htmFRlTags = htmFRlTags ? htmFRlTags.join("") : "";
          htmENTags = htmENTags ? htmENTags.join("") : "";

          let content = {
            fr: htmFRlTags,
            en: htmENTags,
          };

          let roomSelected = null;
          let stageSelected = null;
          let placeName = {
            fr: "",
            en: "",
          };
          let place = currentContent.hasOwnProperty("place") && currentContent.place;
          if (place) {
            let placeFR =
              place.translations.find((t) => t.locale.code === "fr") &&
              place.translations.find((t) => t.locale.code === "fr").translation;
            if (placeFR) {
              if (placeFR.indexOf("${SRN=") !== -1) {
                // is resourceId
                let resourceSRN = placeFR.match(/SRN=[a-zA-Z0-9-\.\/\?:=&_]*/gim);
                if (resourceSRN && resourceSRN.length) {
                  let resourceId = resourceSRN[0].split("=")[1];
                  let stage = this.state.resources.find((r) => {
                    return r.childrens.find((rc) => rc.id === resourceId);
                  });
                  if (stage) {
                    let room = stage.childrens.find((sc) => sc.id === resourceId);
                    if (room) {
                      stageSelected = { label: stage.title, value: stage._id };
                      roomSelected = {
                        label: room.data["nom_d_usage"] || room.data.reference,
                        value: room.id,
                      };
                    }
                  }
                }
              } else {
                placeName = {
                  fr: placeFR,
                  en:
                    place.translations.find((t) => t.locale.code === "en") &&
                    place.translations.find((t) => t.locale.code === "en").translation
                      ? place.translations.find((t) => t.locale.code === "en").translation
                      : "",
                };
              }
            }
          }

          let topics = null;
          if (currentContent.topics && currentContent.topics.length > 0) {
            topics = this.state.section.topics
              .filter((t) => {
                const contentTopics = currentContent.topics.map((ct) => ct.id);
                return contentTopics.indexOf(t.id) !== -1;
              })
              .map((t) => {
                return {
                  value: t.id,
                  label: this.getTopicTranslation(t),
                };
              });
          }
          let eventToEdit = JSON.parse(JSON.stringify(this.defaultState.eventToEdit));
          if (currentContent.event) {
            eventToEdit.fr.label = this.getEventTitle(currentContent.event, "fr");
            eventToEdit.en.label = this.getEventTitle(currentContent.event, "en");
            eventToEdit.startDate = new Date(currentContent.event.startDate);
            eventToEdit.endDate = new Date(currentContent.event.endDate);
          }

          if (header[this.state.lang].url) {
            getImageLightness(header[this.state.lang].url, (b) => {
              this.setState({
                showTopicCreation: false,
                publication,
                title,
                brightness: b,
                subtitle,
                header,
                toBePushed,
                hideFutureEvent,
                groupsSelected,
                content,
                roomSelected,
                stageSelected,
                placeName,
                topics,
                eventToEdit,
                needConfirmationToClose: false,
              });
            });
          } else {
            this.setState({
              showTopicCreation: false,
              publication,
              brightness: 255,
              title,
              subtitle,
              header,
              toBePushed,
              hideFutureEvent,
              groupsSelected,
              content,
              roomSelected,
              stageSelected,
              placeName,
              topics,
              eventToEdit,
              needConfirmationToClose: false,
            });
          }
        } else {
          let currentContent = this.state.selectedContent;
          let title = this.state.selectedContent.title;
          let subtitle = this.state.selectedContent.subtitle;
          let formOperator =
            this.state.selectedContent.timeOffset < 0
              ? { label: "Après", value: "-" }
              : { label: "Avant", value: "+" };
          let timeOffset = Math.abs(this.state.selectedContent.timeOffset);
          let fromEnd = this.state.selectedContent.fromEnd
            ? { label: "Fin", value: true }
            : { label: "Début", value: false };
          let groupsSelected = null;
          if (currentContent.groups && currentContent.groups.length) {
            groupsSelected = this.state.groups.map((g) => {
              if (currentContent.groups.find((g2) => g2 === "" + g.id))
                return { label: g.label, value: g.id };
            });
          }
          this.setState({
            title,
            subtitle,
            formOperator,
            groupsSelected,
            fromEnd,
            timeOffset,
            needConfirmationToClose: true,
          });
        }
      }
    }
  }
  // Quills.js editor onChange
  handleContentChange = (value) => {
    let content = this.state.content;
    content[this.state.lang] = value !== "<p><br></p>" ? value : value.replace("<p><br></p>", "");
    this.setState({ content });
  };
  handleTitleChange = (e) => {
    const { value, maxLength } = e.target;
    const newTitle = value.slice(0, maxLength);
    let title = this.state.title;
    title[this.state.lang] = newTitle;
    this.setState({ title, needConfirmationToClose: true });
  };
  handleSubtitleChange = (e) => {
    const { value, maxLength } = e.target;
    const newSubtitle = value.slice(0, maxLength);
    let subtitle = this.state.subtitle;
    subtitle[this.state.lang] = newSubtitle;
    this.setState({ subtitle, needConfirmationToClose: true });
  };
  // React multiselect onChange
  handleGroupChange = (selectedOption) => {
    this.setState({ groupsSelected: selectedOption, needConfirmationToClose: true });
  };
  handleFromChange = (selectedOption) => {
    this.setState({ fromEnd: selectedOption });
  };
  handleOperatorChange = (selectedOption) => {
    this.setState({ formOperator: selectedOption });
  };
  handleSubmit = async (isUpdate = false) => {
    let {
      groupsSelected,
      title,
      content,
      header,
      publication,
      hideFutureEvent,
      toBePushed,
      subtitle,
      timeOffset,
      fromEnd,
      topics,
      eventToEdit,
    } = this.state;
    if (!this.props.isReminder) {
      let data = {
        title: {
          translations: [
            {
              locale: {
                code: "fr",
              },
              translation: title["fr"],
            },
            {
              locale: {
                code: "en",
              },
              translation: title["en"],
            },
          ],
        },
        subtitle: {
          translations: [
            {
              locale: {
                code: "fr",
              },
              translation: subtitle["fr"],
            },
            {
              locale: {
                code: "en",
              },
              translation: subtitle["en"],
            },
          ],
        },
        header: {
          type: "IMAGE",
          medias: [],
        },
        publication: Date.parse(publication),
        hideFutureEvent: hideFutureEvent,
        toBePushed: toBePushed,
        notification: Date.parse(publication),
      };

      if (header["fr"].id)
        data.header.medias.push({
          locale: {
            code: "fr",
          },
          media: {
            id: header["fr"].id,
          },
        });
      if (header["en"].id)
        data.header.medias.push({
          locale: {
            code: "en",
          },
          media: {
            id: header["en"].id,
          },
        });
      if (groupsSelected && groupsSelected.length) {
        if (this.state.forceGroupsSelected) {
          data.groups = groupsSelected.map((g) => {
            return { id: g.id };
          });
        } else {
          data.groups = groupsSelected.map((g) => {
            return { id: g.value };
          });
        }
      }

      let contentFR = content["fr"];
      let contentEN = content["en"];

      let tagRegex = /(<*([^>]+)>)/gi;
      let contentFRTags = contentFR.match(tagRegex);
      let contentENTags = contentEN.match(tagRegex);
      if (contentFRTags) {
        for (let i = 0; i < contentFRTags.length; i++) {
          if (/<img.* src=\"?[a-zA-Z0-9-\.\/\?:=&]*\"?>/.test(contentFRTags[i])) {
            let baseUrl = contentFRTags[i].split("?")[0].split("/");
            let mediaId = parseInt(baseUrl[baseUrl.length - 1]);
            contentFRTags[i] = `<image data-imageid="${mediaId}"/>`;
          }
        }
      }
      if (contentENTags) {
        for (let i = 0; i < contentENTags.length; i++) {
          if (/<img.* src=\"?[a-zA-Z0-9-\.\/\?:=&]*\"?>/.test(contentENTags[i])) {
            let baseUrl = contentENTags[i].split("?")[0].split("/");
            let mediaId = parseInt(baseUrl[baseUrl.length - 1]);
            contentENTags[i] = `<image data-imageid="${mediaId}"/>`;
          }
        }
      }

      contentFRTags = contentFRTags ? contentFRTags.join("") : "";
      contentENTags = contentENTags ? contentENTags.join("") : "";
      data.content = {
        type: "HTML",
        translations: [
          {
            locale: {
              code: "fr",
            },
            translation: contentFRTags,
          },
          {
            locale: {
              code: "en",
            },
            translation: contentENTags,
          },
        ],
      };
      if (this.state.roomSelected) {
        data.place = {
          type: "TEXT",
          translations: [
            {
              locale: {
                code: "fr",
              },
              translation: "${SRN=" + this.state.roomSelected.value + "}",
            },
            {
              locale: {
                code: "en",
              },
              translation: "${SRN=" + this.state.roomSelected.value + "}",
            },
          ],
        };
      } else if (this.hasCustomPlaceName()) {
        data.place = {
          type: "TEXT",
          translations: [
            {
              locale: {
                code: "fr",
              },
              translation: this.state.placeName["fr"],
            },
            {
              locale: {
                code: "en",
              },
              translation: this.state.placeName["en"],
            },
          ],
        };
      }

      if (topics && topics.length > 0) {
        data.topics = topics.map((t) => {
          return { id: t.value };
        });
      }
      if (eventToEdit && eventToEdit.startDate && eventToEdit.endDate) {
        data.event = {
          title: {
            translations: [
              {
                locale: {
                  code: "fr",
                },
                translation: eventToEdit.fr.label || "",
              },
              {
                locale: {
                  code: "en",
                },
                translation: eventToEdit.en.label || "",
              },
            ],
          },
          startDate: eventToEdit.startDate.toISOString
            ? eventToEdit.startDate.toISOString()
            : new Date(eventToEdit.startDate).toISOString(),
          endDate: eventToEdit.endDate.toISOString
            ? eventToEdit.endDate.toISOString()
            : new Date(eventToEdit.endDate).toISOString(),
          siteId: this.state.roomSelected ? this.props.campusData.id : null,
          resourceId: this.state.roomSelected
            ? this.state.resources
                .find((r) => r.id === this.state.stageSelected.value)
                .childrens.find((c) => c.id === this.state.roomSelected.value).uid
            : null,
          placeId: this.state.roomSelected
            ? this.state.resources
                .find((r) => r.id === this.state.stageSelected.value)
                .childrens.find((c) => c.id === this.state.roomSelected.value).externalId
            : null,
        };
      }

      if (isUpdate) {
        data.id = this.state.selectedContent.id;
      }
      this.setState({ isUploading: true });

      try {
        if (topics === null || isEmpty(topics)) {
          this.setState({ errTopics: "Vous devez ajouter un topics", isUploading: false });
          return;
        }

        if (title[this.state.lang].length === 0 || isEmpty(title)) {
          this.setState({ errTopics: "Vous devez ajouter un titre", isUploading: false });
          return;
        }

        if (!isUpdate) {
          let selectedContent = await this.Swizi.postContent(data, this.swiziProdSectionId).then(
            () => {
              message.success("création d'une news");
            },
          );
          this.setState({ selectedContent, isUploading: false, needConfirmationToClose: false });
          if (this.SwiziTest && this.swiziTestSectionId) {
            data.toBePushed = false;
            this.SwiziTest.postContent(data, this.swiziTestSectionId);
          }
        } else {
          let selectedContent = await this.Swizi.updateContent(data, this.swiziProdSectionId).then(
            () => {
              message.success("modification d'une news");
            },
          );
          this.setState({ selectedContent, isUploading: false, needConfirmationToClose: false });
        }
      } catch (err) {
        this.setState({ err: "Echec lors de la publication du contenu.", isUploading: false });
      }
      try {
        let contents = await this.Swizi.getContents(this.swiziProdSectionId, false);
        this.setState({ contents });
      } catch (err) {
        this.setState({ err: "Echec lors de la récupération des contenus.", isUploading: false });
      }
    } else {
      this.setState({ isUploading: true });
      let groups = groupsSelected
        .filter((gs) => gs && gs.hasOwnProperty("value"))
        .map((gs) => "" + gs.value);
      if (this.state.formOperator.value === "-") {
        timeOffset = -timeOffset;
      }
      let notif = {
        groups,
        title,
        content: subtitle,
        kind: "push",
        timeOffset,
        fromEnd: fromEnd.value,
      };
      if (isUpdate) {
        notif.id = this.state.selectedContent.id;
        this.setState({ isUploading: false });
      } else {
        this.setState({ isUploading: false });
      }
    }
  };
  handleDelete = async (id) => {
    this.setState({ isUploading: true });
    if (!this.props.isReminder) {
      try {
        await this.Swizi.deleteContent(id);
      } catch (err) {
        this.setState({ err: "Echec lors de la suppression du contenu.", isUploading: false });
      }
      try {
        let contents = await this.Swizi.getContents(this.swiziProdSectionId, false);
        this.setState({ isUploading: false, contents });
      } catch (err) {
        this.setState({ err: "Echec lors de la récupération des contenus.", isUploading: false });
      }
    } else {
      try {
        console.log(id);
      } catch (err) {
        this.setState({ err: "Echec lors de la suppression du contenu.", isUploading: false });
      }
      try {
        this.setState({ isUploading: false });
      } catch (err) {
        this.setState({ err: "Echec lors de la récupération des contenus.", isUploading: false });
      }
    }
  };
  getMedia = (content) => {
    if (this.state.medias && content.header) {
      let mediaItem = content.header.medias.find((m) => m.locale.code === "fr");
      if (mediaItem?.media?.id) {
        let id = mediaItem.media.id;
        let mediaRef = this.state.medias.content.find((c) => c.id === id);
        if (mediaRef) {
          let url = mediaRef.url;
          return `url(${url})`;
        } else if (mediaItem.media && mediaItem.media.url) {
          return `url(${mediaItem.media.url})`;
        }
      }
    }
  };
  getSubtitle = (content, lang = this.props.i18n.languages[0]) => {
    if (content.subtitle) {
      if (!this.props.isReminder) {
        let subtitle = content?.subtitle?.translations.find(
          (t) => t.locale.code === lang,
        )?.translation;
        if (!subtitle)
          subtitle = content?.subtitle?.translations.find(
            (t) => t.locale.code === "en",
          )?.translation;
        if (!subtitle)
          subtitle = content?.subtitle?.translations.find((t) => !!t.translation)?.translation;
        return subtitle || "";
      } else {
        if (content.subtitle["fr"]) return content.subtitle["fr"];
        else return "";
      }
    }
  };

  getTitle = (content, lang = this.props.i18n.languages[0]) => {
    if (content.title) {
      if (!this.props.isReminder) {
        let title = content?.title?.translations.find((t) => t.locale.code === lang)?.translation;
        if (!title)
          title = content?.title?.translations.find((t) => t.locale.code === "en")?.translation;
        if (!title) title = content?.title?.translations.find((t) => !!t.translation)?.translation;
        return title || "";
      } else {
        if (content.title["fr"]) return content.title["fr"];
        else return "";
      }
    }
  };
  getPublication = (content) => {
    const { t } = this.props;
    if (!this.props.isReminder) {
      return content.hasOwnProperty("publication")
        ? `${t("Published_On")} : ${moment(content.publication).format("L")} ${t("At")} ${moment(
            content.publication,
          ).format("LT")}`
        : `${t("Published_On")} : ${moment(content.creation).format("L")} ${t("At")} ${moment(
            content.creation,
          ).format("LT")}`;
    } else {
      return `${t("Created_On")} : ${moment(content.createdAt).format("L")} ${t("At")} ${moment(
        content.createdAt,
      ).format("LT")}`;
    }
  };
  handleStageChange = (data) => {
    this.setState({ stageSelected: data, needConfirmationToClose: true });
  };
  handleRoomChange = (data) => {
    this.setState({ roomSelected: data, needConfirmationToClose: true });
  };
  hasCustomPlaceName = () => {
    return this.state.placeName["fr"].length || this.state.placeName["en"].length;
  };
  getPreviewRatio = () => {
    switch (this.state.previewSelected.value) {
      case 0:
        return { width: "375px", height: "812px" };
      case 1:
        return { width: "320px", height: "568px" };
      case 2:
        return { width: "375px", height: "667px" };
      case 3:
        return { width: "360px", height: "640px" };
      case 4:
        return { width: "411px", height: "823px" };
      default:
        return { width: "375px", height: "812px" };
    }
  };
  formatCode = () => {
    let content = this.state.content;
    content["fr"] = html_beautify(content["fr"]);
    content["en"] = html_beautify(content["en"]);
    this.setState({ content });
  };
  // Image cropper
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        blob.fileName = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(blob);
      }, "image/jpeg");
    });
  }
  onImageLoaded = (image) => {
    this.imageRef = image;
  };
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };
  onCropChange = (crop, percentCrop) => {
    this.setState({ tmpMediaCrop: crop });
  };
  makeClientCrop = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const tmpCroppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
        this.state.tmpMedia.name,
      );
      this.setState({ tmpCroppedImage });
    }
  };
  onChangeAddPhoto = async (e) => {
    if (e.target.files && e.target.files[0]) {
      try {
        // this.setState({ isUploading: true })
        let file = e.target.files[0];
        e.target.value = null;
        if (file.size < 2e6) {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            this.setState({ tmpMedia: { src: reader.result, name: file.name } });
          });
          reader.readAsDataURL(file);
        } else {
          this.setState({
            err: "Le fichier est trop lourd. La taille maximale autorisée est de 2 Mo.",
            isUploading: false,
            errAction: "cancel",
          });
        }
      } catch (err) {
        this.setState({ err: "Echec lors de l'ajout du média", isUploading: false });
      }
    }
  };
  onCancelCroppedImage = () => {
    this.imageRef = null;
    this.setState({
      tmpMedia: { src: null, name: null },
      tmpMediaCrop: {
        unit: "%",
        aspect: 2 / 1,
        width: 100,
      },
      tmpCroppedImage: null,
    });
  };
  onSaveCroppedImage = async () => {
    if (this.state.tmpCroppedImage) {
      this.setState({ isUploading: true });
      const file = this.state.tmpCroppedImage;
      let postImage = await this.Swizi.postBlobMedia(file, this.state.tmpMedia.name);
      let tag = this.state.forceGroupsSelected
        ? [this.state.forceGroupsSelected[0].label]
        : ["news-builder"];

      await this.Swizi.tagMedia(postImage.id, tag);

      let medias = await this.Swizi.getMedias(tag);
      this.setState({ medias, isUploading: false });
      this.onCancelCroppedImage();
    }
  };
  // Topics
  areTopicsAvailable = () => {
    return (
      this.state.section.type === "NEWS" &&
      this.state.section.topics &&
      this.state.section.topics.length > 0
    );
  };
  getTopics = () => {
    if (this.state.section.topics) {
      return this.state.section.topics.map((t) => {
        return {
          value: t.id,
          label: this.getTopicTranslation(t),
        };
      });
    }
    return [];
  };
  getTopic = (topicId) => {
    const foundTopics = this.state.section.topics.filter((t) => t.id === topicId);
    if (foundTopics.length > 0) {
      return this.getTopicTranslation(foundTopics[0]);
    }
    return null;
  };
  getDefaultTopicTranslation = (topic) => {
    if (topic && topic.label && topic.label.translations && topic.label.translations.length > 0) {
      let topicName = topic.label.translations[0];
      if (topicName) return <span className="placeholder">{topicName.translation}</span>;
    }
    return "";
  };
  getTopicTranslation = (topic, lang = this.state.lang, defaultTranslation = true) => {
    if (topic && topic.label && topic.label.translations) {
      let topicName = topic.label.translations.find((t) => t.locale.code === lang);
      if (topicName && topicName.translation.length > 0) return topicName.translation;
    }
    if (defaultTranslation) {
      return this.getDefaultTopicTranslation(topic);
    } else {
      return "";
    }
  };
  getContentsOfTopic = (topicId) => {
    return this.state.contents.filter((c) => c.topics.indexOf(topicId) !== -1);
  };
  updateTopicsOfContent = (topics) => {
    this.setState({ topics, needConfirmationToClose: true });
  };
  addTopic = async () => {
    let topic = {
      label: {
        translations: [
          {
            locale: {
              code: "fr",
            },
            translation: this.state.topicToEdit.fr.label || "",
          },
          {
            locale: {
              code: "en",
            },
            translation: this.state.topicToEdit.en.label || "",
          },
        ],
      },
    };

    if (this.state.topicToEdit.id) {
      topic.id = this.state.topicToEdit.id;
    }

    if (this.state.topicToEdit.icon) {
      topic.icon = {
        type: "IMAGE",
        medias: [
          {
            locale: {
              code: "fr",
            },
            media: {
              id: this.state.topicToEdit.icon,
            },
          },
        ],
      };
    }

    if (this.state.topicToEdit.order === -1) {
      if (this.state.section.topics && this.state.section.topics.length > 0) {
        topic.order = this.state.section.topics[this.state.section.topics.length - 1].order + 1;
      } else {
        topic.order = 0;
      }
    } else {
      topic.order = this.state.topicToEdit.order;
    }

    let topics = this.state.section.topics || [];
    if (topic.id) {
      topics = topics.map((t) => {
        return t.id === topic.id ? topic : t;
      });
    } else {
      topics.push(topic);
    }

    await this.saveTopics(topics);
  };
  getTopicsOptionsForSection = () => {
    let options;
    if (this.state.section.options && this.state.section.options.hasOwnProperty("showTrendings")) {
      options = {
        showTrendings: this.state.section.options.showTrendings,
      };
    }
    return options;
  };
  saveTopics = async (topics, showLoader = true, options = this.getTopicsOptionsForSection()) => {
    if (showLoader) this.setState({ isUploading: true });
    let topicsForSection = await this.Swizi.saveTopics(this.state.section.id, topics, options);
    let section = this.state.section;
    if (topicsForSection) {
      if (topicsForSection.options) {
        section.options = { ...(section.options || {}), ...topicsForSection.options };
      }
      if (topicsForSection.topics && topicsForSection.topics.length) {
        section.topics = topicsForSection.topics;
      }
    }
    this.setState({
      section,
      topicToEdit: JSON.parse(JSON.stringify(this.defaultState.topicToEdit)),
      isUploading: false,
    });
  };
  reorderTopics = (reorderedList) => {
    reorderedList = reorderedList.map((item, index) => {
      item.order = index;
      return item;
    });
    this.saveTopics(reorderedList, false);
  };
  // Event
  getEventTitle = (event, lang = this.state.lang) => {
    if (event && event.title && event.title.translations) {
      let title = event.title.translations.find((t) => t.locale.code === lang);
      if (title) return title.translation;
    }
    return "";
  };
  updateEvent = (
    startDate = this.state.eventToEdit.startDate,
    endDate = this.state.eventToEdit.endDate,
    eventTitleFR,
    eventTitleEN,
  ) => {
    let event = this.state.eventToEdit;

    if (startDate) {
      event.startDate = startDate;
      if (event.startDate.getFullYear() > 9999) {
        event.startDate.setFullYear(9999);
      } else if (event.startDate.getFullYear() < 1000) {
        event.startDate.setFullYear(1000);
      }
    } else event.startDate = null;

    if (!isNil(endDate)) {
      event.endDate = endDate;
      if (event.endDate.getFullYear() > 9999) {
        event.endDate.setFullYear(9999);
      } else if (event.endDate.getFullYear() < 1000) {
        event.endDate.setFullYear(1000);
      }
    } else event.endDate = null;

    const setTitleForLang = (title, lang) => {
      event[lang].label = title;
    };
    if (!isNil(eventTitleFR)) {
      setTitleForLang(eventTitleFR, "fr");
    }
    if (!isNil(eventTitleEN)) {
      setTitleForLang(eventTitleEN, "en");
    }

    this.setState({ eventToEdit: event, needConfirmationToClose: true });
  };
  getBrightnessRatio = () => {
    return parseInt((this.state.brightness / 255) * 100);
  };
  renderContent = (content) => {
    return (
      <div
        className="SwiziNewsEditor__ContentItem"
        style={!this.props.isReminder ? { backgroundImage: this.getMedia(content) } : {}}
        key={content.id}
      >
        {this.state.section.type === "NEWS" && content.topics && content.topics.length > 0 ? (
          <div className="SwiziNewsEditor__ContentItem--Topics">
            {content.topics.map((t) => {
              const topic = this.getTopic(t.id);
              if (topic) {
                return (
                  <div
                    className="SwiziNewsEditor__ContentItem--Topic"
                    key={content.id + "-" + t.id}
                  >
                    {topic}
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : null}
        <div className="SwiziNewsEditor__ContentItem--Body">
          <div className="SwiziNewsEditor__ContentItem--Body-BG"></div>
          <div className="SwiziNewsEditor__ContentItem--Body-Title">{this.getTitle(content)}</div>
          <div className="SwiziNewsEditor__ContentItem--Body-SubTitle">
            {this.getSubtitle(content)}
          </div>
          <div className="SwiziNewsEditor__ContentItem--Body-Date">
            {this.getPublication(content)}
          </div>
          <div className="SwiziNewsEditor__ContentItem--Body-Actions">
            <div
              className="SwiziNewsEditor__ContentItem--Body-Actions-Container SwiziNewsEditor__ContentItem--Body-Actions-Container-Edit"
              onClick={() => this.setState({ selectedContent: content })}
            >
              <Edit className="SwiziNewsEditor__ContentItem--Body-Actions-Item" />
            </div>
            <div
              className="SwiziNewsEditor__ContentItem--Body-Actions-Container SwiziNewsEditor__ContentItem--Body-Actions-Container-Delete"
              onClick={() => this.handleDelete(content.id)}
            >
              <Delete className="SwiziNewsEditor__ContentItem--Body-Actions-Item" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderPreview = (mustBeDarken) => {
    return (
      <>
        <div
          className="SwiziNewsEditor__Preview--Container-Header"
          style={{
            backgroundImage: `url(${this.state.header[this.state.lang].url})`,
          }}
        >
          <div
            className="SwiziNewsEditor__Preview-Container-Header-Title"
            style={mustBeDarken ? { color: "var(--SwiziNewsEditor__HeaderColor)" } : {}}
          >
            {this.state.title[this.state.lang]}
          </div>
        </div>

        <div className="SwiziNewsEditor__Preview-Container-Body">
          <div className="SwiziNewsEditor__Preview-Container-Body-Schedule">
            <div className="SwiziNewsEditor__Preview-Container-Body-Schedule-Item">
              <Calendar className="SwiziNewsEditor__Preview-Container-Body-Icon" />
              <div className="SwiziNewsEditor__Preview-Container-Body-Schedule-Item-Label">
                {`${moment(this.state.publication).format("dddd")} ${moment(
                  this.state.publication,
                ).format("LL")}`}
              </div>
            </div>
            <div
              className="SwiziNewsEditor__Preview-Container-Body-Schedule-Item"
              style={{ marginLeft: "8px", justifyContent: "center" }}
            >
              <TimeClock className="SwiziNewsEditor__Preview-Container-Body-Icon" />
              <div className="SwiziNewsEditor__Preview-Container-Body-Schedule-Item-Label">
                {moment(this.state.publication).format("LT")}
              </div>
            </div>
          </div>

          {this.state.roomSelected || this.hasCustomPlaceName() ? (
            <div
              className="SwiziNewsEditor__Preview-Container-Body-Schedule-Item"
              style={{
                padding: "16px 41px",
              }}
            >
              <Marker className="SwiziNewsEditor__Preview-Container-Body-Icon" />
              <div className="SwiziNewsEditor__Preview-Container-Body-Schedule-Item-Label">
                {this.state.roomSelected
                  ? this.state.roomSelected.label
                  : this.state.placeName[this.state.lang]}
              </div>
            </div>
          ) : null}
          <div className="SwiziNewsEditor__Preview-Container-Body-HTML-Content">
            {ReactHtmlParser(this.state.content[this.state.lang])}
          </div>
        </div>
      </>
    );
  };
  renderNewsPreview = () => {
    const { t } = this.props;
    return (
      <>
        <div className="SwiziNewsEditor__Preview-Container-Header-Title NewsSection">
          {this.state.title[this.state.lang]}
        </div>
        <div className="SwiziNewsEditor__Preview-Container-HeaderDate NewsSection">{`${t(
          "Published_On",
        )} ${moment(this.state.publication).format("LLLL")}`}</div>
        <div className="SwiziNewsEditor__Preview-Container-Header-Subtitle NewsSection">
          {this.state.subtitle[this.state.lang]}
        </div>
        <div className="SwiziNewsEditor__Preview--Container-Header-Image NewsSection">
          <img src={this.state.header[this.state.lang].url} />
        </div>
        <div
          style={{ fontSize: "13px" }}
          className="SwiziNewsEditor__Preview-Container-Body-HTML-Content NewsSection ql-editor"
        >
          {ReactHtmlParser(this.state.content[this.state.lang])}
        </div>
      </>
    );
  };
  render() {
    const { t } = this.props;
    let groupsOptions = [];
    let fromOptions = [
      { label: "Début", value: false },
      { label: "Fin", value: true },
    ];
    let fromOperatorOptions = [
      { label: "Avant", value: "+" },
      { label: "Après", value: "-" },
    ];
    if (this.state.groups.length) {
      groupsOptions = this.state.groups.map((g) => {
        return { label: g.label, value: g.id };
      });
    }
    let stagesOptions = [];
    if (this.state.hasRM && this.state.resources.length) {
      stagesOptions = this.state.resources.map((r) => {
        return { label: r.title, value: r._id };
      });
      stagesOptions.push({ label: t("noFloorSelect") });
    }
    let roomOptions = [];
    if (this.state.stageSelected) {
      let stage = this.state.resources.find((s) => s._id === this.state.stageSelected.value);
      if (stage) {
        roomOptions = stage.childrens
          .filter((r) => r.title.length)
          .map((r) => {
            return { label: r.title, value: r.id };
          });
      }
    }
    let previewOptions = [
      { value: 0, label: "iPhone X" },
      { value: 1, label: "iPhone 5/SE" },
      { value: 2, label: "iPhone 6/7/8" },
      { value: 3, label: "Galaxy S5" },
      { value: 4, label: "Pixel 2 XL" },
    ];

    let mustBeDarken = this.getBrightnessRatio() >= 75 ? true : false;
    let containerClass = this.props.isReminder
      ? "SwiziNewsEditor__Settings--Container Reminder"
      : "SwiziNewsEditor__Settings--Container";

    return this.state.selectedContent ? (
      <div
        className="SwiziNewsEditor SwiziNewsEditor__fade-in-top"
        style={this.props.isReminder ? { alignItems: "center" } : {}}
      >
        {this.state.isUploading ? (
          <div className="SwiziNewsEditor__Uploading-State">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : null}
        {this.state.err ? (
          <div className="SwiziNewsEditor__Uploading-State">
            <div className="SwiziNewsEditor__Uploading-State-Body">
              <Error className="SwiziNewsEditor__LoaderContainer--SVG" />
              <div className="SwiziNewsEditor__LoaderContainer--Label">{this.state.err}</div>
              <div
                className="SwiziNewsEditor__Button--Main"
                onClick={() =>
                  this.state.errAction === "cancel"
                    ? this.setState({ err: null, errAction: null })
                    : this.initialize()
                }
              >
                {t("Back")}
              </div>
            </div>
          </div>
        ) : null}
        {this.state.errTopics ? (
          <div className="SwiziNewsEditor__Uploading-State">
            <div className="SwiziNewsEditor__Uploading-State-Body">
              <Error className="SwiziNewsEditor__LoaderContainer--SVG" />
              <div className="SwiziNewsEditor__LoaderContainer--Label">{this.state.errTopics}</div>
              <div
                className="SwiziNewsEditor__Button--Main"
                onClick={() => this.setState({ errTopics: null })}
              >
                {t("Back")}
              </div>
            </div>
          </div>
        ) : null}
        {this.state.wannaBack ? (
          <div className="SwiziNewsEditor__Uploading-State">
            <div className="SwiziNewsEditor__Uploading-State-Body">
              <AddContentBG className="SwiziNewsEditor__LoaderContainer--SVG" />
              <div className="SwiziNewsEditor__LoaderContainer--Label">{t("Sure_Leave")}</div>
              <div className="SwiziNewsEditor__Button--Main-Container">
                <div
                  className="SwiziNewsEditor__Button--Main"
                  onClick={() => this.setState({ wannaBack: false, selectedContent: null })}
                >
                  {t("Yes_Leave")}
                </div>
                <div
                  className="SwiziNewsEditor__Button--Main"
                  onClick={() => this.setState({ wannaBack: false })}
                >
                  {t("Cancel")}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="SwiziNewsEditor__Topbar">
          <div
            className="SwiziNewsEditor__Topbar--Back-Container"
            onClick={() =>
              this.state.needConfirmationToClose
                ? this.setState({ wannaBack: true })
                : this.setState({ selectedContent: null })
            }
          >
            <Back className="SwiziNewsEditor__Topbar--Back" />
            {/* <div className="SwiziNewsEditor__Topbar--Back-Label">Retour</div> */}
          </div>
          <div className="SwiziNewsEditor__Tppnar--Title">
            {this.props.isReminder ? t("Notif_Editing") : t("Content_Editing")}
          </div>
          {this.props.isReminder ? (
            this.state.groupsSelected && this.state.groupsSelected.length ? (
              <div
                className={
                  this.state.groupsSelected && this.state.groupsSelected.length
                    ? "SwiziNewsEditor__Button--Main"
                    : "SwiziNewsEditor__Button--Main SwiziNewsEditor__Button--Main-Disabled"
                }
                onClick={() =>
                  this.state.groupsSelected && this.state.groupsSelected.length
                    ? this.state.selectedContent === "new"
                      ? this.handleSubmit()
                      : this.handleSubmit(true)
                    : () => {}
                }
              >
                {this.state.selectedContent === "new" ? t("Publish") : t("Update")}
              </div>
            ) : (
              <div className="SwiziNewsEditor__Button--Main-Unavailabke">{t("Select_A_Group")}</div>
            )
          ) : (
            <div
              className={"SwiziNewsEditor__Button--Main"}
              onClick={() =>
                this.state.selectedContent === "new" ? this.handleSubmit() : this.handleSubmit(true)
              }
            >
              {this.state.selectedContent === "new" ? t("Publish") : t("Update")}
            </div>
          )}
        </div>
        <div
          className={containerClass}
          style={
            this.state.isUploading || this.state.err || this.state.wannaBack
              ? { filter: "blur(1px)" }
              : {}
          }
        >
          <div
            className="SwiziNewsEditor__Settings"
            style={this.props.isReminder ? { borderLeft: "1px solid rgba(0,0,0,0.125)" } : {}}
          >
            {!this.state.showLibrary ? (
              <div className="SwiziNewsEditor__SettingsItem">
                <div className="SwiziNewsEditor__SettingsItem--Label">{t("Languages")}</div>
                <div className="SwiziNewsEditor__SettingsItem--SubLabel">
                  {t("Edit_Internationalized")}
                </div>
                <div className="SwiziNewsEditor__SettingsItem--Buttons">
                  <div
                    className={
                      this.state.lang === "fr"
                        ? "SwiziNewsEditor__SettingsItem--Button-Selected SwiziNewsEditor__SettingsItem--Button"
                        : "SwiziNewsEditor__SettingsItem--Button"
                    }
                    onClick={() => {
                      moment.locale("fr");
                      this.setState({ lang: "fr" });
                    }}
                  >
                    {t("French")}
                  </div>
                  <div
                    className={
                      this.state.lang === "en"
                        ? "SwiziNewsEditor__SettingsItem--Button-Selected SwiziNewsEditor__SettingsItem--Button"
                        : "SwiziNewsEditor__SettingsItem--Button"
                    }
                    onClick={() => {
                      moment.locale("en");
                      this.setState({ lang: "en" });
                    }}
                  >
                    {t("English")}
                  </div>
                </div>
              </div>
            ) : null}
            {!this.props.isReminder ? (
              <div
                className={
                  this.state.showLibrary
                    ? "SwiziNewsEditor__Library SwiziNewsEditor__Library--Open"
                    : "SwiziNewsEditor__Library"
                }
              >
                <div
                  className="SwiziNewsEditor__SettingsItem"
                  style={{ flex: 2, background: "#fafafa" }}
                >
                  <div
                    className="SwiziNewsEditor__SettingsItem--Label"
                    style={{
                      padding: "16px 0",
                    }}
                  >
                    <div>{t("Image_Library")}</div>
                    <div
                      className="SwiziNewsEditor__Library--Close"
                      onClick={() => this.setState({ showLibrary: false, wantToAddPhoto: false })}
                    >
                      <Back />
                    </div>
                  </div>
                  <div className="SwiziNewsEditor__SettingsItem--LibraryContainer">
                    <div className="SwiziNewsEditor__SettingsItem--LibraryItem">
                      <input
                        type="file"
                        className="SwiziNewsEditor__ContentItem--Input"
                        accept="image/jpeg, image/png"
                        onChange={(e) => {
                          this.onChangeAddPhoto(e);
                        }}
                      />
                      <Add className="SwiziNewsEditor__ContentItem--New-SVG" />
                      <div className="SwiziNewsEditor__ContentItem--Label">{t("Add_Image")}</div>
                    </div>
                    {this.state.medias &&
                      this.state.medias.content.map((m) => {
                        return (
                          <img
                            key={m.id}
                            alt={m.filename}
                            className={
                              this.state.header[this.state.lang].id &&
                              this.state.header[this.state.lang].id === m.id
                                ? "SwiziNewsEditor__SettingsItem--LibraryItem SwiziNewsEditor__SettingsItem--LibraryItemSelected"
                                : "SwiziNewsEditor__SettingsItem--LibraryItem"
                            }
                            src={m.url}
                            onClick={() => {
                              if (!this.state.wantToAddPhoto) {
                                let header = this.state.header;
                                header[this.state.lang].id = m.id;
                                header[this.state.lang].url = m.url;
                                getImageLightness(m.url, (brightness) => {
                                  this.setState({
                                    header,
                                    brightness,
                                    needConfirmationToClose: true,
                                  });
                                });
                              } else {
                                let cursorPos = this.reactQuillRef.getEditorSelection();
                                if (cursorPos && (cursorPos.index || cursorPos.index === 0)) {
                                  let mediaURL = `<img src=${m.url} />`;
                                  this.reactQuillRef
                                    .getEditor()
                                    .clipboard.dangerouslyPasteHTML(cursorPos.index, mediaURL);
                                }
                              }
                            }}
                          ></img>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : null}
            {!this.state.showLibrary && !this.props.isReminder ? (
              <div className="SwiziNewsEditor__SettingsItem">
                <div className="SwiziNewsEditor__SettingsItem--Label">{t("Header_Image")}</div>
                <div className="SwiziNewsEditor__SettingsItem--SubLabel"></div>

                <div className="SwiziNewsEditor__SettingsItem--Select" style={{ display: "flex" }}>
                  {!this.state.header[this.state.lang].id ? (
                    <div
                      className="SwiziNewsEditor__SettingsItem--LibrarySelect"
                      onClick={() => this.setState({ showLibrary: true })}
                    >
                      {t("Select_Image")}
                    </div>
                  ) : (
                    <img
                      src={this.state.header[this.state.lang].url}
                      alt={"Selectionnée"}
                      className="SwiziNewsEditor__SettingsItem--ImageSelected"
                      onClick={() => this.setState({ showLibrary: true })}
                    />
                  )}
                </div>
              </div>
            ) : null}
            {!this.state.showLibrary ? (
              <div className="SwiziNewsEditor__SettingsItem">
                <div className="SwiziNewsEditor__SettingsItem--Label">{t("Title")}</div>
                <div className="SwiziNewsEditor__SettingsItem--SubLabel"></div>
                <div className="SwiziNewsEditor__SettingsItem--Select">
                  <input
                    type="text"
                    value={this.state.title[this.state.lang]}
                    onChange={(e) => this.handleTitleChange(e)}
                    placeholder={`${t("Choose_Title")}...`}
                    maxLength="255"
                  />
                </div>
              </div>
            ) : null}
            {!this.state.showLibrary ? (
              <div className="SwiziNewsEditor__SettingsItem">
                <div className="SwiziNewsEditor__SettingsItem--Label">{t("Subtitle")}</div>
                <div className="SwiziNewsEditor__SettingsItem--SubLabel Counter">
                  {(this.state.subtitle[this.state.lang] || "").length} / 255
                </div>

                <div className="SwiziNewsEditor__SettingsItem--Select">
                  <textarea
                    value={this.state.subtitle[this.state.lang]}
                    onChange={(e) => this.handleSubtitleChange(e)}
                    placeholder={`${t("Choose_Subtitle")}...`}
                    maxLength="255"
                  />
                </div>
              </div>
            ) : null}
            {!this.state.showLibrary && !this.props.isReminder ? (
              <div className="SwiziNewsEditor__SettingsItem">
                <div className="SwiziNewsEditor__SettingsItem--Label">{t("Publication")}</div>
                <div className="SwiziNewsEditor__SettingsItem--SubLabel">
                  {t("Content_To_Be_Shawn")} :
                </div>
                <div className="SwiziNewsEditor__SettingsItem--Select">
                  <DateTimePicker
                    value={this.state.publication}
                    onChange={(date) =>
                      this.setState({ publication: date, needConfirmationToClose: true })
                    }
                    locale={this.props.i18n.language}
                  />
                  <input
                    type="checkbox"
                    className="ios-switch"
                    id="b"
                    checked={this.state.hideFutureEvent}
                    onChange={(e) =>
                      this.setState({
                        hideFutureEvent: e.target.checked,
                        needConfirmationToClose: true,
                      })
                    }
                  />
                  <label htmlFor="b">
                    <span className="sw"></span>
                    {t("Hide_Content")}
                  </label>
                  <input
                    type="checkbox"
                    className="ios-switch"
                    id="push"
                    checked={this.state.toBePushed}
                    onChange={(e) =>
                      this.setState({ toBePushed: e.target.checked, needConfirmationToClose: true })
                    }
                  />
                  <label htmlFor="push">
                    <span className="sw"></span>
                    {t("Send_Notif")}
                  </label>
                </div>
              </div>
            ) : null}

            {!this.state.showLibrary && !this.props.isReminder && this.areTopicsAvailable() ? (
              <div className="SwiziNewsEditor__SettingsItem">
                <div className="SwiziNewsEditor__SettingsItem--Label">{t("Topics")}</div>
                <div className="SwiziNewsEditor__SettingsItem--SubLabel">
                  {t("Select_Topics")} :
                </div>
                <div className="SwiziNewsEditor__SettingsItem--Select">
                  <Select
                    value={this.state.topics}
                    onChange={this.updateTopicsOfContent}
                    isMulti
                    options={this.getTopics()}
                    placeholder={t("Select_Topics")}
                    noOptionsMessage={() => t("No_Topic")}
                  />
                </div>
              </div>
            ) : null}

            {!this.state.showLibrary &&
            !this.props.isReminder &&
            this.state.section.type === "NEWS" ? (
              <div className="SwiziNewsEditor__SettingsItem">
                <div className="SwiziNewsEditor__SettingsItem--Label">{t("Event")}</div>
                <div className="SwiziNewsEditor__SettingsItem--SubLabel">
                  {t("Event_description")} :
                </div>
                <div className="SwiziNewsEditor__SettingsItem--Select" style={{ marginBottom: 8 }}>
                  <input
                    type="text"
                    value={this.state.eventToEdit[this.state.lang].label || ""}
                    onChange={(e) =>
                      this.updateEvent(
                        null,
                        null,
                        this.state.lang === "fr" ? e.currentTarget.value : null,
                        this.state.lang === "en" ? e.currentTarget.value : null,
                      )
                    }
                    placeholder={`${t("Choose_Title")}...`}
                    maxLength="255"
                  />
                </div>
                <div
                  className="SwiziNewsEditor__SettingsItem--Select"
                  style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
                >
                  <div className="SwiziNewsEditor__SettingsItem--Select-Label">
                    {t("Event_startDate")} :
                  </div>
                  <DateTimePicker
                    value={this.state.eventToEdit.startDate}
                    onChange={(date) => this.updateEvent(date, undefined, null, null)}
                    locale={this.props.i18n.language}
                    className={"SwiziNewsEditor__SettingsItem--Select-Flex"}
                  />
                </div>
                <div
                  className="SwiziNewsEditor__SettingsItem--Select"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="SwiziNewsEditor__SettingsItem--Select-Label">
                    {t("Event_endDate")} :
                  </div>
                  <DateTimePicker
                    value={this.state.eventToEdit.endDate}
                    onChange={(date) => this.updateEvent(undefined, date, null, null)}
                    locale={this.props.i18n.language}
                    className={"SwiziNewsEditor__SettingsItem--Select-Flex"}
                  />
                </div>
              </div>
            ) : null}

            {!this.props.isReminder &&
            this.props.format === "localized" &&
            !this.state.showLibrary ? (
              <div className="SwiziNewsEditor__SettingsItem">
                <div className="SwiziNewsEditor__SettingsItem--Label">{t("Location")}</div>
                {this.state.hasRM && this.state.resources.length ? (
                  <div className="SwiziNewsEditor__SettingsItem--SubLabel">
                    {t("Attach_To_Place")}
                  </div>
                ) : (
                  <div className="SwiziNewsEditor__SettingsItem--SubLabel">
                    {t("Attach_To_Place2")}
                  </div>
                )}

                {!this.props.isReminder && this.state.hasRM && this.state.resources.length ? (
                  <div
                    className="SwiziNewsEditor__SettingsItem--Select"
                    style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
                  >
                    <div className="SwiziNewsEditor__SettingsItem--Select-Label">
                      {t("Stage")} :
                    </div>

                    <Select
                      value={this.state.stageSelected}
                      onChange={this.handleStageChange}
                      options={stagesOptions}
                      placeholder={t("Select_A_Stage")}
                      noOptionsMessage={() => {
                        t("No_Stage_Found");
                      }}
                      className={"SwiziNewsEditor__SettingsItem--Select-Flex"}
                      isDisabled={this.hasCustomPlaceName()}
                    />
                  </div>
                ) : null}
                {roomOptions.length ? (
                  <div
                    className="SwiziNewsEditor__SettingsItem--Select"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="SwiziNewsEditor__SettingsItem--Select-Label">{t("Room")}</div>
                    <Select
                      value={this.state.roomSelected}
                      onChange={this.handleRoomChange}
                      options={roomOptions}
                      placeholder={t("Select_A_Room")}
                      noOptionsMessage={() => {
                        t("No_Room_Found");
                      }}
                      className={"SwiziNewsEditor__SettingsItem--Select-Flex"}
                      isDisabled={this.hasCustomPlaceName()}
                    />
                  </div>
                ) : null}
                {/* {!this.props.isReminder && this.state.hasRM && this.state.resources.length ? (
                  <div className="SwiziNewsEditor__SettingsItem--Separator"></div>
                ) : null} */}
                {/* <div
                  className="SwiziNewsEditor__SettingsItem--Select"
                  style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
                >
                  <div className="SwiziNewsEditor__SettingsItem--Select-Label">{t("Name")} :</div>
                  <input
                    type="text"
                    style={{ flex: 2 }}
                    value={this.state.placeName[this.state.lang]}
                    onChange={(e) => {
                      let placeName = this.state.placeName;
                      placeName[this.state.lang] = e.target.value;
                      this.setState({
                        placeName,
                        stageSelected: null,
                        roomSelected: null,
                        needConfirmationToClose: true,
                      });
                    }}
                    placeholder={t("Enter_Name_Place")}
                  />
                </div> */}
              </div>
            ) : null}

            {!this.state.showLibrary && !this.state.forceGroupsSelected ? (
              <div className="SwiziNewsEditor__SettingsItem">
                <div className="SwiziNewsEditor__SettingsItem--Label">
                  {this.props.isReminder ? t("Target") : t("Access")}
                </div>
                <div className="SwiziNewsEditor__SettingsItem--SubLabel">{t("Select_Group")}</div>
                <div className="SwiziNewsEditor__SettingsItem--Select">
                  <Select
                    value={this.state.groupsSelected}
                    onChange={this.handleGroupChange}
                    isMulti
                    options={groupsOptions}
                    placeholder={t("Select_Group2")}
                    noOptionsMessage={() => t("No_Group_Found")}
                  />
                </div>
              </div>
            ) : null}

            {!this.state.showLibrary && this.props.isReminder ? (
              <div className="SwiziNewsEditor__SettingsItem">
                <div className="SwiziNewsEditor__SettingsItem--Label">{t("Notif_Trigger")}</div>
                <div className="SwiziNewsEditor__SettingsItem--SubLabel">
                  Choisissez quand déclencher la notification
                </div>
                <div className="SwiziNewsEditor__SettingsItem--Select Multiple">
                  <input
                    type="number"
                    step="5"
                    style={{ maxWidth: "64px" }}
                    value={this.state.timeOffset}
                    min="0"
                    onChange={(e) => {
                      if (parseInt(e.target.value) && parseInt(e.target.value) >= 0) {
                        this.setState({ timeOffset: parseInt(e.target.value) });
                      } else {
                        this.setState({ timeOffset: 0 });
                      }
                    }}
                    className="SwiziNewsEditor__SettingsItem--Select-Flex"
                  />
                  <div className="SwiziNewsEditor__SettingsItem--SubLabel">minute(s)</div>
                  <Select
                    value={this.state.formOperator}
                    onChange={this.handleOperatorChange}
                    options={fromOperatorOptions}
                    className={"SwiziNewsEditor__SettingsItem--Select-Flex"}
                  />
                  <div className="SwiziNewsEditor__SettingsItem--SubLabel">le/la</div>
                  <Select
                    value={this.state.fromEnd}
                    onChange={this.handleFromChange}
                    options={fromOptions}
                    className={"SwiziNewsEditor__SettingsItem--Select-Flex"}
                  />
                  <div className="SwiziNewsEditor__SettingsItem--SubLabel">du rendez-vous</div>
                </div>
              </div>
            ) : null}
          </div>
          {!this.props.isReminder ? (
            <div className="SwiziNewsEditor__Main">
              {process.env.REACT_APP_HIDE_HTML_EDITOR !== "true" ? (
                <div
                  className="SwiziNewsEditor__Main-Type"
                  onClick={() => this.setState({ showHTML: !this.state.showHTML })}
                >
                  {this.state.showHTML ? t("Show_Editor") : t("Show_HTML")}
                </div>
              ) : undefined}
              {!this.state.showHTML ? (
                <ReactQuill
                  ref={(el) => {
                    this.reactQuillRef = el;
                  }}
                  value={this.state.content[this.state.lang]}
                  onChange={this.handleContentChange}
                  onFocus={() => {
                    this.setState({ needConfirmationToClose: true });
                  }}
                  modules={this.state.modules}
                  formats={this.state.formats}
                  theme="snow"
                />
              ) : (
                <React.Fragment>
                  <div
                    className="SwiziNewsEditor__Edit-Container"
                    onClick={() => this.formatCode()}
                  >
                    <Format className="SwiziNewsEditor__ContentItem--Body-Actions-Item" />
                    <div className="SwiziNewsEditor__Edit-Text">{t("Format_Code")}</div>
                  </div>

                  <AceEditor
                    mode="html"
                    theme="monokai"
                    onChange={(e) => {
                      let content = this.state.content;
                      content[this.state.lang] = e;
                      this.setState({ content, needConfirmationToClose: true });
                    }}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                    value={this.state.content[this.state.lang]}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                    }}
                  />
                </React.Fragment>
              )}
              {/* {this.state.showLibrary ? <div className="SwiziNewsEditor__Popup--BG"></div> : null}  */}
            </div>
          ) : null}
          {!this.props.isReminder ? (
            <div className="SwiziNewsEditor__Preview">
              <div
                className="SwiziNewsEditor__SettingsItem--Label"
                style={{ alignSelf: "flex-start" }}
              >
                {t("Publication_Preview")}
              </div>
              <div
                className="SwiziNewsEditor__SettingsItem--Select"
                style={{ width: "100%", margin: "8px 0" }}
              >
                <Select
                  value={this.state.previewSelected}
                  onChange={(val) => this.setState({ previewSelected: val })}
                  options={previewOptions}
                />
              </div>
              <div className="SwiziNewsEditor__Preview--Container" style={this.getPreviewRatio()}>
                {this.state.section && this.state.section.type === "NEWS"
                  ? this.renderNewsPreview()
                  : this.renderPreview(mustBeDarken)}
              </div>
            </div>
          ) : null}
          {this.state.tmpMedia.src ? (
            <div className="SwiziNewsEditor__SettingsItem--CropContainer">
              <ReactCrop
                src={this.state.tmpMedia.src}
                crop={this.state.tmpMediaCrop}
                maxWidth={1080}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
              <div className="SwiziNewsEditor__SettingsItem--CropContainer--ActionButtons">
                <div
                  className="SwiziNewsEditor__Button--Main SwiziNewsEditor__Button--Main-Disabled"
                  onClick={this.onCancelCroppedImage}
                >
                  {t("Cancel")}
                </div>
                <div className="SwiziNewsEditor__Button--Main" onClick={this.onSaveCroppedImage}>
                  {t("Upload")}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    ) : (
      <div className="SwiziNewsEditor__Selection SwiziNewsEditor__fade-in-bottom">
        {!!this.props.title && (
          <div className="SwiziNewsEditor__Menu-Title">{this.props.title}</div>
        )}
        {this.state.isUploading ? (
          <div className="SwiziNewsEditor__Uploading-State">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : null}
        {this.state.appInitialized && !this.state.err ? (
          <div className="SwiziNewsEditor__ContentsContainer">
            {/* manage topic */}
            {this.state.section && this.state.section.type === "NEWS" ? (
              <div
                className="SwiziNewsEditor__ContentItem SwiziNewsEditor__ContentItem--New"
                onClick={() => {
                  this.setState({ showTopicCreation: true });
                }}
              >
                <AddCategoryBG className="SwiziNewsEditor__ContentItem--New--BG" />
                <div className="SwiziNewsEditor__ContentItem--New-Container">
                  <div className="SwiziNewsEditor__ContentItem--Label">{t("Manage_Topic")}</div>
                </div>
              </div>
            ) : null}
            {/* add content */}
            <div
              className="SwiziNewsEditor__ContentItem SwiziNewsEditor__ContentItem--New"
              onClick={() => this.setState({ selectedContent: "new" })}
            >
              <AddContentBG className="SwiziNewsEditor__ContentItem--New--BG" />
              <div className="SwiziNewsEditor__ContentItem--New-Container">
                <Add className="SwiziNewsEditor__ContentItem--New-SVG" />
                <div className="SwiziNewsEditor__ContentItem--Label">
                  {this.props.isReminder ? t("Add_Notif") : t("Add_News")}
                </div>
              </div>
            </div>
            {/* render contents */}
            {this.state.section
              ? this.state.contents.map((content) => {
                  return this.renderContent(content);
                })
              : null}
            {/* topic editor */}
            {this.state.section && this.state.section.type === "NEWS" ? (
              <div
                className="SwiziNewsEditor__ContentItem--NewTopic"
                data-show={this.state.showTopicCreation}
              >
                {/* title */}
                <div className="SwiziNewsEditor__SettingsItem--Label" style={{ marginBottom: 16 }}>
                  {t("Manage_Topic")}
                  <div
                    className="SwiziNewsEditor__Library--Close"
                    onClick={() =>
                      this.setState({
                        showTopicCreation: false,
                        topicToEdit: JSON.parse(JSON.stringify(this.defaultState.topicToEdit)),
                      })
                    }
                  >
                    <Back />
                  </div>
                </div>

                {/* lang picker */}
                <div className="SwiziNewsEditor__SettingsItem">
                  <div className="SwiziNewsEditor__SettingsItem--Buttons">
                    <div
                      className={
                        this.state.lang === "fr"
                          ? "SwiziNewsEditor__SettingsItem--Button-Selected SwiziNewsEditor__SettingsItem--Button"
                          : "SwiziNewsEditor__SettingsItem--Button"
                      }
                      onClick={() => {
                        moment.locale("fr");
                        this.setState({ lang: "fr" });
                      }}
                    >
                      {t("French")}
                    </div>
                    <div
                      className={
                        this.state.lang === "en"
                          ? "SwiziNewsEditor__SettingsItem--Button-Selected SwiziNewsEditor__SettingsItem--Button"
                          : "SwiziNewsEditor__SettingsItem--Button"
                      }
                      onClick={() => {
                        moment.locale("en");
                        this.setState({ lang: "en" });
                      }}
                    >
                      {t("English")}
                    </div>
                  </div>
                </div>

                {/* showTrendings options */}
                <div className="SwiziNewsEditor__SettingsItem">
                  <div className="SwiziNewsEditor__SettingsItem--Label">
                    <div
                      className="SwiziNewsEditor__SettingsItem--TopicName"
                      data-active={
                        this.state.section.options && this.state.section.options.showTrendings
                          ? "true"
                          : "false"
                      }
                    >
                      {t("Trendings")}
                    </div>
                    <div className="SwiziNewsEditor__SettingsItem--TopicEdit">
                      <input
                        type="checkbox"
                        className="ios-switch"
                        id="trending"
                        checked={
                          this.state.section.options && this.state.section.options.showTrendings
                        }
                        onChange={async (e) => {
                          let topics = JSON.parse(JSON.stringify(this.state.section.topics));
                          let options = {
                            showTrendings: e.target.checked,
                          };
                          await this.saveTopics(topics, false, options);
                        }}
                      />
                      <label htmlFor="trending">
                        <span className="sw"></span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* add topic */}
                <div className="SwiziNewsEditor__SettingsItem SwiziNewsEditor__AddTopic">
                  <div className="SwiziNewsEditor__SettingsItem--Label">
                    {this.state.topicToEdit.id ? t("Update_Topic") : t("Add_Topic")}
                  </div>
                  <div className="SwiziNewsEditor__SettingsItem--SubLabel"></div>

                  <div className="SwiziNewsEditor__SettingsItem--Select">
                    <input
                      type="text"
                      value={this.state.topicToEdit[this.state.lang].label || ""}
                      onChange={(e) => {
                        let topicToEdit = this.state.topicToEdit;
                        topicToEdit[this.state.lang].label = e.target.value;
                        this.setState({ topicToEdit });
                      }}
                      placeholder={`${t("Choose_Title")}...`}
                      maxLength="255"
                    />
                  </div>

                  {/* Save button */}
                  <div className="SwiziNewsEditor__SettingsItem--Buttons" style={{ marginTop: 16 }}>
                    <div
                      className="SwiziNewsEditor__SettingsItem--Button"
                      onClick={() => {
                        this.setState({
                          topicToEdit: JSON.parse(JSON.stringify(this.defaultState.topicToEdit)),
                        });
                      }}
                    >
                      {t("Cancel")}
                    </div>

                    <div
                      className="SwiziNewsEditor__SettingsItem--Button-Selected SwiziNewsEditor__SettingsItem--Button"
                      onClick={() => {
                        this.addTopic();
                      }}
                    >
                      {this.state.topicToEdit.id ? t("Update") : t("Upload")}
                    </div>
                  </div>
                </div>

                {/* existing topics */}
                <div className="SwiziNewsEditor__SettingsItem SwiziNewsEditor__ExistingTopics">
                  <RLDD
                    items={
                      this.state.section && this.state.section.topics
                        ? this.state.section.topics.filter(
                            (t) => t.id !== null && t.id !== undefined,
                          )
                        : []
                    }
                    itemRenderer={(t, index) => {
                      return (
                        <div className="SwiziNewsEditor__SettingsItem--Label" key={t.id}>
                          <div className="SwiziNewsEditor__SettingsItem--TopicReorder">☰</div>
                          <div className="SwiziNewsEditor__SettingsItem--TopicName">
                            {this.getTopicTranslation(t)}
                          </div>
                          <div
                            className="SwiziNewsEditor__SettingsItem--TopicEdit"
                            onClick={() => {
                              const topicToEdit = {
                                fr: {
                                  label: this.getTopicTranslation(t, "fr", false),
                                },
                                en: {
                                  label: this.getTopicTranslation(t, "en", false),
                                },
                                icon: null,
                                id: t.id,
                                order: t.order,
                              };
                              this.setState({ topicToEdit });
                            }}
                          >
                            <Edit className="SwiziNewsEditor__ContentItem--Body-Actions-Item" />
                          </div>
                          <div
                            className="SwiziNewsEditor__SettingsItem--TopicDelete"
                            onClick={async () => {
                              let topics = JSON.parse(JSON.stringify(this.state.section.topics));
                              topics.splice(index, 1);
                              await this.saveTopics(topics);

                              try {
                                this.setState({ isUploading: true });
                                const contents = await this.Swizi.getContents(
                                  this.swiziProdSectionId,
                                  false,
                                );
                                this.setState({ contents, isUploading: false });
                              } catch (err) {
                                this.setState({ err: t("Get_Groups_Error") });
                              }
                            }}
                          >
                            <Delete className="SwiziNewsEditor__ContentItem--Body-Actions-Item" />
                          </div>
                        </div>
                      );
                    }}
                    onChange={this.reorderTopics}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : !this.state.err ? (
          <div className="SwiziNewsEditor__LoaderContainer">
            <Loading className="SwiziNewsEditor__LoaderContainer--SVG" />
            <div className="SwiziNewsEditor__LoaderContainer--Label">{this.state.loadingLabel}</div>
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : null}
        {this.state.err ? (
          <div className="SwiziNewsEditor__Uploading-State">
            <Error className="SwiziNewsEditor__LoaderContainer--SVG" />
            <div className="SwiziNewsEditor__LoaderContainer--Label">{this.state.err}</div>
            <div className="SwiziNewsEditor__Button--Main" onClick={() => this.initialize()}>
              {t("Back")}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
SwiziNewsEditor.propTypes = {
  swiziApiUrl: PropTypes.string.isRequired,
  swiziApiKey: PropTypes.string.isRequired,
  swiziSectionId: PropTypes.number.isRequired,
  rmApiUrl: PropTypes.string,
  rmApiKey: PropTypes.string,
  rmSecretKey: PropTypes.string,
  primaryColor: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  format: PropTypes.string,
  token: PropTypes.string,
  versioning: PropTypes.object,
};
SwiziConnector.defaultProps = {
  swiziApiUrl: "https://api.swizi.io",
  swiziApiKey: "",
  swiziSectionId: "",
  rmApiUrl: "",
  rmApiKey: "",
  rmSecretKey: "",
  primaryColor: "#29bdc1",
  textColor: "#ffffff",
  title: null,
  format: "simple",
  token: null,
};
export default withTranslation()(withArrayTranslate(SwiziNewsEditor));
