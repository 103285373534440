import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Col, Image, Row, Spin, Tag, Typography } from "antd";
import Meta from "antd/lib/card/Meta";
import moment from "moment";
import { isEmpty, isNil } from "ramda";
import { Link } from "react-router-dom";
import { patchFilter } from "../../../../services/redux/services/FastbookingWS";
import Glyph from "../../../Common/Glyph/Glyph";
import Checkbox from "../../../Common/Checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useDesignTokens from "../../../../hook/useDesignTokens";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import styles from "../Fastbooking.module.less";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const CardRoom = ({ setSelectedRoom, setModalDetails }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors } = useDesignTokens();

  const filters = useSelector((state) => state.fastbookingWS.filters);
  const campus = useSelector((state) => state.clientsWS.campus);
  const roomFastbooking = useSelector((state) => state.fastbookingWS.roomFastbooking);

  const isLoading = useSelector(createLoadingSelector(["fastbooking/getRoomFastbooking"]));

  return (
    <Card
      title={
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={5}>
            {t("SpaceAvailable")}
          </Typography.Title>
          <Button type="secondary" size="medium" icon={<Glyph name="maps" />}>
            <Link
              style={{ color: "white" }}
              to="/fastBookingV2/map"
              onClick={() => logEvent(analytics, "fast_booking_map")}
            >
              {t("spas.request.actions.map")}
            </Link>
          </Button>
        </div>
      }
      className={styles["cardRoom"]}
      style={{ overflow: "initial" }}
      headStyle={{
        display: "flex",
        alignItems: "center",
        minHeight: "50px",
        position: "sticky",
        top: "0",
        backgroundColor: "white",
        zIndex: "999",
      }}
    >
      <div style={{ width: "100%", position: "sticky", top: "0", zIndex: "200" }}>
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            padding: "16px",
            backgroundColor: "white",
          }}
        >
          <Button
            style={{ fontSize: "14px" }}
            className={
              isNil(filters.floor) || isEmpty(filters.floor) ? "active-allFilter" : "btnAllFilter"
            }
            onClick={() => {
              dispatch(patchFilter({ floor: null }));
            }}
          >
            <Glyph name="business" style={{ verticalAlign: "-2px", fontSize: "16px" }} />
            {t("AllFloor")}
          </Button>
          {campus?.mapData?.floors.map((floor, index) => (
            <Checkbox
              key={index}
              value={floor.id}
              text={t("Floor") + " " + floor.displayName}
              onClick={(e) => {
                dispatch(patchFilter({ floor: e.target.value }));
              }}
              checked={(filters?.floor && filters?.floor.includes(floor.id)) || isNil(filters)}
            />
          ))}
        </div>
      </div>

      <div style={{ padding: "0 16px" }}>
        <Typography.Text strong type="secondary">
          {roomFastbooking?.rooms?.length} {t("Result")}
        </Typography.Text>
      </div>

      <Spin spinning={false} indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}>
        <Row gutter={[8, 16]} style={{ padding: "16px" }}>
          {[...(roomFastbooking.rooms || [])]
            .sort((a, b) => (a?.resources?.title > b?.resources?.title ? 1 : -1))
            .sort((a, b) => (a?.resources?.floorReference < b?.resources?.floorReference ? -1 : 1))
            ?.map((room, index) => (
              <Col span={6} style={{ position: "relative" }} key={index}>
                {room?.pending ? (
                  <Tag
                    style={{
                      position: "absolute",
                      zIndex: "80",
                      top: "10px",
                      right: "16px",
                    }}
                    color={colors.pending_light}
                  >
                    <Typography.Text strong style={{ color: "white" }}>
                      {t("Treatment")}
                    </Typography.Text>
                  </Tag>
                ) : (
                  room?.availableTime && (
                    <Tag
                      style={{
                        position: "absolute",
                        zIndex: "80",
                        top: "10px",
                        right: "16px",
                      }}
                      color={colors.success_light}
                    >
                      <Typography.Text strong style={{ color: "white" }}>
                        {room?.availableTime >= 60
                          ? `${t("Available")} > 1h`
                          : `${moment
                              .duration(moment(room?.endDate).diff(moment(), "minutes"), "minutes")
                              .minutes()}
mins`}
                      </Typography.Text>
                    </Tag>
                  )
                )}
                <Card
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setModalDetails(true);
                    setSelectedRoom(room);
                  }}
                  cover={
                    room?.resources?.photos && !isEmpty(room?.resources?.photos) ? (
                      <div style={{ boxShadow: "none" }} className={styles["roomPictures"]}>
                        <Image preview={false} src={room.resources.photos[0]} />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "150px",
                          width: "100%",
                          backgroundColor: colors.grey_40,
                          opacity: 0.3,
                        }}
                      >
                        <Glyph
                          style={{ fontSize: "60px", color: "white", opacity: 0.8 }}
                          name={
                            campus?.mapData?.categories?.find(
                              (categorie) => categorie?.id === room?.resources?.categoryId,
                            )?.icon
                          }
                        />
                      </div>
                    )
                  }
                >
                  <Meta
                    title={<Typography.Title level={5}>{room?.resources?.title}</Typography.Title>}
                    description={
                      <div>
                        <Typography.Paragraph>
                          {t("Floor")}{" "}
                          {
                            campus?.mapData?.floors?.find(
                              (floor) => floor.reference === room?.resources?.floorReference,
                            )?.displayName
                          }
                        </Typography.Paragraph>
                        <Typography.Paragraph type="secondary">
                          {
                            campus?.mapData?.categories?.find(
                              (categorie) => categorie.id === room?.resources?.categoryId,
                            )?.title
                          }{" "}
                          * {room?.resources?.features?.seats} {t("Places")}
                        </Typography.Paragraph>
                      </div>
                    }
                  />
                </Card>
              </Col>
            ))}
        </Row>
      </Spin>
    </Card>
  );
};

export default CardRoom;
