/* eslint-disable import/no-anonymous-default-export */
import DWMTrads from "@trad/DWMProduct.json";
import CommonTrads from "./Common.json";

const trads = DWMTrads.reduce((acc, trad) => {
  return { ...acc, [trad.KEY]: trad.fr };
}, {});

const common = CommonTrads.reduce((acc, trad) => {
  return { ...acc, [trad.KEY]: trad.fr };
}, {});

export default {
  translation: {
    //COMMON
    "NoResult": "Aucun résultat",
    "Available": "Disponible",
    "In": "Dans",
    "SeeItinerary": "Voir l'itinéraire",
    "Today": "Aujourd'hui",
    "Filters": "Filtres",
    "Back": "Retour",
    "Next": "Continuer",
    "Finish": "Terminer",
    "Save": "Enregistrer",
    "Floor": "Étage",
    "Cancel": "Annuler",
    "Infos": "Infos",
    "SeeMore": "Voir plus",
    "Full": "Complet",
    "Confirm": "Confirmer",
    "SelectAll": "Tout sélectionner",
    "All": "Tous",
    "PrivacyPolicy": "Politique de confidentialité",
    "CreateActu": "Gérer les actualités",
    "CreateActuPermanent": "Gérer les contenus froids",
    "Hidden": "Masqué",
    "ErrorOccured": "Une erreur s'est produite.",
    "Add": "Ajouter",
    "Refresh": "Rafraichir",
    "Yes": "Oui",
    "Guests": "Invités & Externes",
    "Collaborators": "Collaborateurs",
    "cancel": "Annuler",
    "accept": "Accepter",
    "notAccept": "Ne pas accepter",
    "pleaseRead": "Veuillez lire et accepter les CGU et la politique de confidentialité pour continuer",
    "readthe": "Je confirme avoir lu les",
    "readthe2": "Je confirme avoir lu la",
    "validCGU": "Validez les CGU et la Politique de confidentialité",
    "Next_onboarding": "Suivant",
    "Skip": "Passer",
    "toStart": "Commencer",
    "Languages": "Langues",
    "French": "Français",
    "English": "Anglais",
    "Dutch": "Néerlandais",
    "Closed": "Fermer",

    "OCCUPIED": "Occupé",
    "AVAILABLE": "Disponible",

    // LOGIN
    "Welcome": "Bienvenue",
    "OnDashboard": "Sur votre portail, connectez-vous pour continuer.",
    "Login": "Me connecter",

    // LOADER
    "Loading": "Chargement en cours",
    "LoadingMap": "Chargement de la carte...",
    "LoadingPlanning": "Chargement de l'agenda",

    //HEADER

    //DASHBOARD
    "booking": "réservation",
    "bookings": "réservations",
    "WelcomeDesk": "Accueil",

    //FastBooking
    "SpaceAvailable": "Espace(s) disponible(s)",
    "CurrentReservation": "Mes réservations",
    "Book": "Réserver",
    "BookDuring": "Réserver pendant",
    "CancelBooking": "Annuler la réservation",
    "BackHome": "Retour à la liste",
    "BookingArea": "Espace réservé",
    "BookingDontWork": "La réservation n’a pas été effectuée",
    "TryAgain": "Réessayez de réserver la salle à nouveau.",
    "Erase": "Effacer les filtres",
    "Apply": "Appliquer",
    "SpacesAvailable": "Afficher les espaces bientôt disponible",
    "ReservationDetail": "Détails de la réservation",
    "Slots": "Créneau horaire",
    "numberPeople": "Nombre de personne(s)",
    "AllSpaces": "Tous les espaces",
    "NoResultFloor": "Aucun résultat à cet étage",
    "TrySearching": "Essayez de rechercher à un autre étage ou supprimez des filtres.",
    "AvailbleFloor": "Disponibilité dans les autres étages",
    "ResultFloor": "résultat(s) à cet étage",
    "Equipements": "Équipement(s)",

    //fastbooking evo
    "SeeSpaces": "Voir la liste des espaces",
    "BookThe": "Réserver le",
    "From": "À partir de",
    "From_": "de",
    "When": "Pendant",
    "MyDay": "Ma journée",
    "Consultbook": "Cliquez ici pour consulter toutes vos réservations",
    "ApplyFilter": "Appliquer les filtres",
    "AvancedSearch": "Recherche avancée",
    "AllFloor": "Tous les étages",
    "Services": "Services",
    "Result": "résultat(s)",
    "Treatment": "En cours de traitement",
    "Places": "place(s)",
    "BookCanceled": "Réservation annulée",
    "ErrorCancelBook": "Impossible d'annuler la réservation",
    "Spaces": "Espace(s)",
    "SelectTheSpace": "Sélectionner l'espace",
    "Of": "de",
    "NumberPlaces": "Nombre de place(s)",
    "None": "Aucun",
    "SearchByName": "Rechercher par nom",
    "Until": "Jusqu'à",
    "To": "à",
    "Started": "Début à",
    "StartedThe": "Début le",
    "AdvertisementMessage": "Attention, au delà de cette date, les espaces en libre service ne seront pas affichés.",
    "fastbooking.sureCanceled": "Êtes-vous sûr de vouloir annuler la réservation ?",
    "fastbooking.rooms.types": "Type d'espace",
    "fastbooking.updateSite": "Changer de site",
    "fastbooking.redirectSite": "Vous allez être redirigé sur le site de {{site}}. Une fois connecté, cliquez sur {{fastbooking_name}} pour réserver un espace.",
    "backToFilter": "Retour aux filtres",

    //GuidMe
    "FindSpace": "Saisir le nom de l'espace",
    "LastSearch": "Mes dernières recherches",
    "Favoris": "Mes favoris",
    "Itinerary": "Itinéraire",
    "Duration": "Durée",
    "Start": "Saisir un point de départ",
    "End": "Saisir un point d'arrivée",

    //Ticketing
    "Requests": "Mes tickets",
    "NewTicket": "Nouveau Ticket",
    "New": "Nouveau",
    "TakenIntoAccount": "Pris en compte",
    "Suspended": "Suspendu",
    "Close": "Fermé",
    "NoWaiting": "Aucun nouveau ticket",
    "NoTaken": "Aucun ticket pris en compte",
    "NoSuspended": "Aucun ticket suspendu",
    "NoClose": "Aucun ticket fermé",
    "Status": "Statut",
    "Location": "Localisation",
    "Pictures": "Photos",
    "By": "Par",
    "Category": "Catégorie",
    "SelectCategory": "Séléctionner une catégorie",
    "ChooseLocation": "Définir une localisation",
    "WriteSomething": "Ajoutez des précisions sur votre demande et sa localisation",
    "Adds": "Ajouter des documents",
    "YourRequest": "Votre demande est prise en compte",
    "Informed": "Vous serez informé·e de l’avancement de votre demande dans l’application",
    "UseSearch": "Utilisez le champ de recherche pour trouver un espace.",
    "closed": "Fermé",
    "CreateTicket": "Créer un ticket",
    "AddComment": "Ajouter un commentaire",
    "AllRequest": "Tous mes tickets",
    "Summary": "Résumé",
    "Followed": "Suivi",
    "Discussion": "Discussion",
    "Subcategory": "Sous-catégorie",
    "MoreDetails": "Saisie une sous-catégorie",
    "Description": "Description",
    "errorTicket": "Erreur lors de la création du ticket",
    "errorDoc": "Vous avez atteint la limite du nombre de documents autorisés",
    "refresh": "Actualiser",

    /**
     * VISITORS
     */
    "visitors.delegated": "Mes visites déléguées",
    "visitors.forms.visit.create": "Créer une nouvelle visite",
    "visitors.forms.visit.update": "Mettre à jour la visite",
    "visitors.forms.visit.delete": "Supprimer la visite",
    "visitors.forms.visit.fields.resident": "Inviter au nom de quelqu'un d'autre",
    "visitors.forms.visit.fields.visitors": "Ajouter des invités",
    "visitors.forms.visit.fields.visitors.helper": "Saisissez le nom de la personne que vous souhaitez ajouter à l’invitation. Si vous ne trouvez pas cette personne dans le référentiel, entrez simplement son adresse email.",
    "visitors.forms.user.create": "Créer une fiche visiteur",
    "visitors.forms.user.update": "Modifier la fiche visiteur",
    "visitors.forms.user.fields.firstname": "Prénom",
    "visitors.forms.user.fields.lastname": "Nom de famille",
    "visitors.forms.user.fields.login": "Adresse mail",
    "visitors.forms.user.fields.login.forbidden": "Ce nom de domaine n'est pas autorisé pour créer une fiche visiteur",
    "visitors.forms.user.fields.company": "Société",
    "visitors.forms.user.fields.function": "Fonction",
    "visitors.forms.visit.detail": "Détail de votre visite",

    /**
     * WIFI
     */
    "wifi.ssid": "Nom du réseau",
    "wifi.password": "Mot de passe",
    "wifi.helper.title": "Gagnez du temps !",
    "wifi.helper.description": "Scannez ce QR Code avec votre smartphone pour vous connecter automatiquement au réseau Wifi.",

    /**
     * SPACE AS A SERVICE
     */
    "spas": "Space As a Service",
    "spas.status.PENDING": "En attente",
    "spas.status.PENDING_other": "En attentes",
    "spas.status.UNASSIGNED": "Non affecté",
    "spas.status.UNASSIGNED_other": "Non affectés",
    "spas.status.APPROVED": "Déclaration acceptée",
    "spas.status.APPROVED_other": "Déclaration acceptée",
    "spas.status.DECLINED": "Refusée",
    "spas.status.DECLINED_other": "Refusées",
    "spas.status.CANCELLED": "Annulée",
    "spas.status.CANCELLED_other": "Annulées",
    "spas.period.MORNING": "Matinée",
    "spas.period.AFTERNOON": "Après-midi",
    "spas.period.DAY": "Journée",
    "spas.recurrence.NONE": "Aucune",
    "spas.recurrence.RECURRENCE": "Récurrence :",
    "spas.recurrence.EVERY_DAY": "Tous les jours",
    "spas.recurrence.EVERY_WEEK": "Tous les {{day}}s",
    "spas.recurrence.EVERY_MONTH": "Tous les mois",
    "spas.recurrence.EVERY_2_WEEK": "Un {{day}} sur deux",
    "spas.history.createRequest": "La requête a été créé",
    "spas.history.approve": "Une date a été accepté",
    "spas.history.approveAll": "Toutes les dates ont été accéptés",
    "spas.history.decline": "Une date a été annulé",
    "spas.history.declineAll": "Toutes les dates ont été annulé",
    "spas.history.cancel": "Une date a été annulé",
    "spas.history.cancelAll": "Toutes les dates ont été annulés",
    "spas.history.overrided": "Des date ont été annulé pour libérer l'espace",
    "spas.planning": "Planning",
    "spas.request": "Demande",
    "spas.request.details": "Détails de le demande",
    "spas.request.update": "Modification de la déclaration",
    "spas.request.new": "Nouvelle déclaration",
    "spas.request.cancel": "Annulation de la déclaration",
    "spas.request.comments": "Commentaires",
    "spas.request.id.title": "Demande ID {{id}}",
    "spas.request.site": "Où souhaitez vous travailler ce jour ?",
    "spas.request.isSerie": "Cette réservation comporte une occurence",
    "spas.request.startDate.title": "Date de début",
    "spas.request.startDate.helper": "Démarre le",
    "spas.request.period.title": "Durée",
    "spas.request.period.helper": "Sélectionnez une durée",
    "spas.request.recurrence": "Récurrence",
    "spas.request.endDate": "Se termine le {{date}}",
    "spas.request.forceEndDate": "Forcer la date de fin",
    "spas.request.from": "De : {{user}}",
    "spas.request.comment.title": "Commentaire",
    "spas.request.comment.helper": "Ajouter un commentaire (obligatoire)",
    "spas.request.actions.confirmBooking": "Confirmer la réservation",
    "spas.request.actions.book": "Continuer",
    "spas.request.actions.map": "Voir sur le plan",
    "spas.request.actions.workplaces": "Voir la liste",
    "spas.request.actions.approve": "Affecter une place",
    "spas.request.actions.approve_other": "Affecter des places",
    "spas.request.actions.decline": "Refuser la demande",
    "spas.request.actions.decline_other": "Refuser les demandes",
    "spas.request.actions.cancel": "Annuler la déclaration",
    "spas.request.actions.updateReservation": "Modifier la déclaration",
    "spas.request.actions.saveReservation": "Enregistrer les modifications",
    "spas.request.actions.updateSpace": "Modifier l'affectation",
    "spas.request.actions.updateSpace_other": "Modifier les affectations",
    "spas.request.actions.removeSpace": "Supprimer l'affectation",
    "spas.request.actions.removeSpace_other": "Supprimer les affectations",
    "spas.request.actions.occurence": "Uniquement cette journée",
    "spas.request.actions.serie": "Toute la série",
    "spas.request.actions.assignValidate": "Valider l'affectation",
    "spas.request.actions.assignIn": "Affecter dans {{desk}}",
    "spas.request.actions.assignAuto": "Affecter automatiquement",
    "spas.request.actions.assignAuto.selectMore": "Vous devez sélectionner plus de salle pour atteindre un nombre de place suffisant",
    "spas.request.actions.assignAuto.helper": "Sélectionner dans quel(s) espace(s) affecter {{count}} personne",
    "spas.request.actions.assignAuto.helper_other": "Sélectionner dans quel(s) espace(s) affecter {{count}} personnes",
    "spas.request.backCalendar": "Retour au calendrier",
    "spas.request.seeDay": "Voir ma journée",
    "spas.request.reservation": "Enregistrement en cours",
    "spas.request.resaAccepted": "Déclaration acceptée",
    "spas.request.resaRefused": "La déclaration n'a pas été effectuée",
    "spas.request.tryAgain": "Veuillez réessayer",
    "spas.conflict": "Le poste de travail sélectionné pour {{user}} est déjà occupé sues les dates suivantes :",
    "spas.conflict.question": "Que souhaitez vous faire ?",
    "spas.conflict.date": "Date",
    "spas.conflict.user": "Utilisateur",
    "spas.conflict.forceAssign": "Affecter quand même",
    "spas.conflict.forceAssign.helper": "Les affectations ci-dessus seront annulées. Chaque personne devra effectuer une nouvelle demande.",
    "spas.conflict.onlyAvailbale": "Affecter sur les jours disponibles",
    "spas.conflict.onlyAvailbale.helper": "Ce poste sera attribué à {{user}} uniquement sur les dates disponibles. Une nouvelle demande devra être effectuée pour les jours ci-dessus.",
    "spas.admin.header.week": "Semaine {{number}}",
    "spas.admin.header.weekPrevious": "Semaine précédente",
    "spas.admin.header.weekNext": "Semaine suivante",
    "spas.admin.header.pending": "Demandes en attente",
    "spas.admin.header.approved": "Demandes validés",
    "spas.admin.header.estimated": "Occupation estimée",
    "spas.admin.header.available": "{{count}} / {{total}} disponible",
    "spas.admin.header.available_other": "{{count}} / {{total}} disponibles",
    "spas.admin.header.occupied": "{{count}} / {{total}} place occupée",
    "spas.admin.header.occupied_other": "{{count}} / {{total}} places occupées",
    "spas.admin.header.map": "Voir le plan du bâtiment",
    "spas.admin.tabs.search": "Rechercher une personne",
    "spas.admin.tabs.selected": "{{count}} sélectionnée",
    "spas.admin.tabs.selected_other": "{{count}} sélectionnées",
    "spas.admin.tabs.resident": "Nom résident",
    "spas.admin.tabs.manager": "Nom manager",
    "spas.admin.tabs.from": "Auteur demande",
    "spas.admin.tabs.createdAt": "Date de la demande",
    "spas.admin.tabs.recurrence": "Récurrence",
    "spas.admin.tabs.space": "Emplacement",
    "spas.admin.tabs.affectedAt": "Date de l'affectation",
    "spas.admin.tabs.declinedAt": "Date refus",
    "spas.admin.map.search.title": "Sélectionner un espace",
    "spas.admin.map.search.helper": "Utilisez la carte ou le champ de recherche pour sélectionner un espace à attribuer.",
    "spas.admin.map.search": "Rechercher un espace",
    "spas.widget.noResa": "Aucune déclaration aujourd'hui",
    "spas.manage.followingPerson": "Personne(s) suivie(s)",
    "spas.manage.waiting.invitation": "invitation(s) en attente",
    "spas.manage.lengthPerson": "Appuyez ici pour consulter vos invitations en attente",
    "spas.manage.noResult": "Aucun résultat dans vos personnes suivies.",
    "spas.manage.addPerson": "Ajouter des personnes",
    "spas.manage.stopFollow": "Ne plus suivre",
    "spas.manage.noFollower": "Vous ne suivez aucune personne pour le moment.",
    "spas.manage.searchFollower": "Recherchez une personne dans l’annuaire de votre organisation et envoyez une invitation.",
    "spas.manage.received.invitation": "Invitations reçues",
    "spas.manage.shareNotebook": "Appuyez sur une invitation pour partager votre agenda.",
    "spas.manage.accepted": "Accepter l'invitation",
    "spas.manage.ignored": "Ignorer l'invitation",
    "spas.messages.create.error": "Impossible de modifier la déclaration",
    "spas.messages.cancel.error": "Impossible d'annuler la déclaration",
    "spas.messages.decline.error": "Impossible de refuser la demande",
    "spas.messages.removeSpace.error": "Impossible de supprimer l'affectation",
    "spas.messages.conflicts.error": "Impossible de vérifier la disponibilité de l'espace",
    "spas.people.present": "Qui sera présent sur site ?",
    "spas.people.whoResa": "Découvrez qui sera présent ce jour",
    "spas.people.noResult": "Aucun résultat dans vos personnes suivies pour ce jour.",
    "spas.people.find": "Rechercher dans les personnes suivies",
    "spas.messages.cancel.success": "La demande d'annulation a bien été prise en compte",
    "spas.request.confirm.presence": "Confirmer ma présence",
    "spas.request.validate": "Valider",
    "spas.presence.confirmed": "Présence confirmée",
    "spas.presence.present": "Votre présence a déjà été confirmée",
    "spas.presence.error": "Une erreur est survenue, votre présence n’a pas pu être confirmée. Veuillez réessayer.",
    "spas.presence.today": "Votre présence n’a pas pu être confirmée. Vous pouvez confirmer votre présence uniquement durant la période de réservation correspondante.",
    "spas.presence.success": "Vous avez confirmé votre présence",
    "spas.presence.code": "Saisir le code à 6 chiffres situé sur l’étiquette de bureau",
    "spas.place.available": "place(s) disponible(s)",
    "spas.workplace.title": "Poste de travail",
    "space.workplace.subtitle": "Sélectionnez un poste de travail",
    "spas.workplace.your": "Votre espace de travail",
    "spas.workplace.free": "places disponible(s)",
    "spas.workplacemap.helper": "Sélectionnez un bureau disponible en cliquant sur le plan.",
    "spas.prsence.alreadyConfirmed": "Déjà confirmé",
    "spas.presence.noConfirmed": "Vous ne pouvez pas confirmer votre présence sur une réservation qui n’a pas démarré",
    "spas.request.error.sectors": "Aucun secteur attribué",
    "spas.request.error.sectors.content": "Aucun secteur ne vous est attribué sur ce site.",
    "spas.request.error.date": "Date de début supérieure à la date de fin",

    /**
     * PHONE BOOK
     */

    "phoneBook.seachPhoneBook": "Recherchez une personne dans l’annuaire de votre organisation",
    "phonebook.useSearch": "Utilisez le champ de saisie situé en haut de l’écran pour trouver une personne en écrivant les 3 premiers caractères de son nom ou de son prénom.",
    "phoneBook.backToSearch": "Retour à la recherche",
    "phoneBook.title": "Annuaire",
    "phoneBook.send.invitation": "Envoyer une invitation",
    "phoneBook.success": "Invitation envoyée",
    "phoneBook.error": "L'invitation n'a pas été envoyée",
    "phoneBook.error.alreadySend": "L'invitation a déjà été envoyée",
    "phoneBook.number.contact": "Affichage de {{nbItems}}/{{totalItems}} contacts. Affinez votre recherche pour trouver la personne que vous recherchez.",
    "phoneBook.search": "Rechercher un contact",

    /**
     * CONFORT
     */

    "Stage": "Étage",
    "Temperature": "Température",
    "Lighting": "Éclairage",
    "Ventilation": "Ventilation",
    "Blind": "Store",
    "Blinds": "Tous les stores",
    "Light": "Lumière",
    "ControlEquipment": "Contrôlez les équipements de confort d’un espace en le sélectionnant sur la partie gauche de l’écran.",

    // SEARCH ROOM
    "SelectSpace": "Sélectionnez un espace",
    "UseCard": "Utilisez le champ de recherche pour trouver un espace à piloter.",
    "ResultSearch": "Résultat de la recherche",
    "SearchSpace": "Rechercher un espace...",

    // ERROR
    "ErrorSetLight": "Un problème est survenu sur l'éclairage",
    "ErrorSetBlind": "Un problème est survenu sur les stores",
    "ErrorSetBlindAngle": "Un problème est survenu sur l'inclinaison des stores",
    "ErrorSetTemperatures": "Un problème est survenu sur la température",
    "ErrorSetVentilation": "Un problème est survenu sur la ventilation",

    "ErrorModeAuto": "Impossible de lancer le mode auto",

    "ErrorGetLight": "Une erreur s'est produite sur l'éclairage, veuillez réessayer.",
    "ErrorGetBlind": "Une erreur s'est produite sur les stores, veuillez réessayer.",
    "ErrorGetTemperatures": "Une erreur s'est produite sur la température, veuillez réessayer.",
    "ErrorGetVentilation": "Une erreur s'est produite sur la ventilation, veuillez réessayer.",
    "ErrorGetComfort": "Le confort de la salle n'a pas pu être récupéré. Veuillez relancer une recherche.",

    // POWERBI
    "TypeDuration": "Choisissez ici d’afficher les résultats sur la journée entière ou uniquement sur une demie-journée.",
    "IntervalDate": "Sélectionnez un intervalle de date pré-sélectionné ou définissez un intervalle personnalisé.",
    "Interval": "Intervalle",

    // AGORA
    "Places.Frequentation": "Affluence",
    "Places.Now": "Actuellement",
    "Places.Informations": "Informations",
    "Places.hours": "Horaires d'ouvertures",
    "Places.Location": "Voir l'emplacement",
    "Places.Equipments": "Équipements",
    "Places.others": "Autres",
    "Places.Open": "Ouvert",
    "Places.Closed": "Fermé",
    "Places.Services": "Services",
    "Places.Clicked": "Cliquer ici",

    // FACILITIES
    "facilities.week": "Semaine {{number}}",
    "facilities.weekPrevious": "Semaine précédente",
    "facilities.weekNext": "Semaine suivante",
    "facilities.dashboard.noEvent": "Aucun événement prévu ce jour.",
    "facilities.resident.helper": "Suivez l'organisation de vos événements et collaborez facilement avec les services généraux.",
    "facilities.resident.noEvent.title": "Vous n'avez aucun événement à venir.",
    "facilities.resident.noEvent.subtitle": "Réservez un espace dans votre calendrier Outlook pour que les services généraux organisent votre événement.",
    "facilities.filters.categories": "Catégories",
    "facilities.filters.rooms": "Espaces",
    "facilities.filters.rooms.rooms": "Espace",
    "facilities.filters.rooms.types": "Type d'espace",
    "facilities.filters.organizer": "Organisateur",
    "facilities.filters.hidden": "Afficher les événements masqués",
    "facilities.filters.new": "Masquer les événements non traités",
    "facilities.filters.tasks": "Masquer les événements sans action en cours",
    "facilities.filters.erase": "Effacer les filtres",
    "facilities.event.new": "Nouvel événement",
    "facilities.event.notHandled": "Non traité",
    "facilities.event.noCategory": "Non catégorisé",
    "facilities.event.tasks.tooltip": "{{completed}} actions sur {{total}}",
    "facilities.event.subscribed": "Les mails d'alerte sont activés",
    "facilities.event.notSubscribed": "Les mails d'alerte ne sont pas activés",
    "facilities.event.helper": "Dans l'onglet Général, renommez l'événement et attribuez lui une catégorie pour le retrouver facilement. \n Ajoutez de nouveaux onglets pour répartir les informations entre les différents contributeurs.",
    "facilities.event.tab.new": "Nouvel onglet",
    "facilities.event.tab.helper": "Donnez un nom à cet onglet puis ajoutez des contributeurs. \n Personnalisez cet onglet en utilisant le mode édition en haut à droite, bouton (...).",
    "facilities.event.tab.general": "Général",
    "facilities.event.tab.description": "Notes",
    "facilities.event.section.TEXT": "Zone de texte",
    "facilities.event.section.TEXT.helper": "Ajoutez un paragraphe de texte qui sera visible de tous les contributeurs.",
    "facilities.event.section.TEXT.new": "Nouvelle zone de texte",
    "facilities.event.section.RADIO": "Liste de choix",
    "facilities.event.section.RADIO.helper": "Ajoutez une liste de choix, celle-ci pourra être modifiée par les contributeurs.",
    "facilities.event.section.RADIO.new": "Nouvelle Liste de choix",
    "facilities.event.section.LIST": "Liste à puce",
    "facilities.event.section.LIST.helper": "Créez une liste à puces, celle-ci pourra être modifiée par les contributeurs.",
    "facilities.event.section.LIST.new": "Nouvelle liste à puce",
    "facilities.event.section.CHECKBOX": "Liste d'actions",
    "facilities.event.section.CHECKBOX.helper": "Listez ici les différentes actions à mener. Les contributeurs pourront modifier la liste des actions et marquer une action comme réalisée.",
    "facilities.event.section.CHECKBOX.new": "Nouvelle liste d'actions",
    "facilities.event.section.FILE": "Pièces jointes",
    "facilities.event.section.FILE.helper": "Téléchargez des fichiers qui seront visibles par les contributeurs. \n Taille maximum : 50Mo",
    "facilities.event.section.FILE.new": "Nouvelle zone de pièces jointes",
    "facilities.event.section.add": "Ajoutez une section de votre choix",
    "facilities.event.hide": "Masquer l'événement",
    "facilities.event.hide_off": "Réafficher l'événement",
    "facilities.event.date": "Date",
    "facilities.event.startDate": "Débute le",
    "facilities.event.endDate": "Se termine le",
    "facilities.event.location": "Lieu",
    "facilities.event.description": "Description",
    "facilities.event.category": "Catégorie",
    "facilities.event.organizer": "Organisateur",
    "facilities.event.save_cancel": "Annuler les changements",
    "facilities.event.save": "Sauvegarder",
    "facilities.event.contributors": "Contributeurs",
    "facilities.event.contributors.add": "Ajouter un contributeur",
    "facilities.event.contributors.remove": "Retirer",
    "facilities.event.contributors.helper": "Ajoutez ici des personnes faisant partie de votre organisation. \n Les contributeurs auront accès uniquement à cet onglet. Ils pourront collaborez avec vous en modifiant le contenu et en ajoutant des éléments à cet onglet.",
    "facilities.event.externals": "Externes",
    "facilities.event.externals.add": "Ajouter un email externe",
    "facilities.event.externals.remove": "Retirer",
    "facilities.event.externals.helper": "Ajoutez ici des personnes en dehors de votre organisation. \n Elles seront notifiées par mail des modifications ayant lieu sur cet onglet.",
    "facilities.event.edit.open": "Editer cet onglet",
    "facilities.event.edit.close": "Quitter le mode édition",
    "facilities.event.delete": "Supprimer l'onglet",
    "facilities.event.comments": "Commentaires",
    "facilities.event.comments.add": "Rédiger un commentaire",
    "facilities.event.comments.send": "Envoyer",
    "facilities.categories.new": "Créer une catégorie",
    "facilities.files.status.NEW": "Nouveau",
    "facilities.files.status.PENDING": "En attente",
    "facilities.files.status.VALIDATED": "Validé",
    "facilities.files.status.DECLINED": "Refusé",
    "facilities.files.status.ABANDONED": "Abandonné",
    "facilities.files.remove": "Retirer",
    "facilities.files.upload.title": "Cliquez ou faites glisser un fichier dans cette zone pour charger un fichier",
    "facilities.files.upload.save": "Enregistrer ce(s) fichier(s)",

    //PUBLIC
    "Public.activated": "Votre compte est activé !",
    "Public.connected": "Connectez-vous dès à présent sur l'application mobile et sur le portail web avec votre identifiant",
    "Public.connected2": "Connectez-vous dès à présent sur l'application mobile avec votre identifiant",
    "Public.upload": "Téléchargez l’application mobile depuis les stores d’applications ou rendez-vous sur le portail web en utilisant les liens ci-dessous.",
    "Public.upload2": "Téléchargez l’application mobile depuis les stores d’applications en utilisant les liens ci-dessous.",
    "Public.error.activated": "Votre compte est déjà activé",
    "Public.password.verification": "Vérification de l'utilisateur",
    "Public.password.activated": "Activez votre compte",
    "Public.password.experience": "Pour profiter de l’expérience sur notre application mobile et sur le portail web, activez votre compte invité en choisissant un mot de passe.",
    "Public.password.identifiant": "Identifiant",
    "Public.password.email": "Utilisez l’adresse email sur laquelle vous avez reçu votre mail d’invitation.",
    "Public.password.password": "Choisissez un mot de passe",
    "Public.password.obligatory": "Le champ mot de passe est obligatoire",
    "Public.password.strongPassword": "Choisissez un mot de passe fort en suivant les conditions suivantes.",
    "Public.password.eight": "8 caractères min.",
    "Public.password.one": "1 caractère spécial",
    "Public.password.maj": "1 majuscule",
    "Public.password.number": "1 chiffre",
    "Public.password.min": "1 minuscule",
    "Public.password.confirm": "Confirmez votre mot de passe",
    "Public.password.same": "Mots de passe identiques",
    "Public.password.active": "Activer mon compte",
    "Public.expired.finish": "Votre réservation est terminée",
    "Public.expired.free": "Vous ne pouvez pas libérer / conserver votre espace",
    "Public.failure.request": "Votre demande n’a pu être prise en compte.",
    "Public.failure.spaces": "Votre demande concernant la réservation de l'espace {{title}} de {{startHour}} à {{endHour}} n’a pas pu être traitée suite à une erreur.",
    "Public.failure.excuse": "Nous nous excusons pour la gêne occasionnée et vous invitons à réessayer plus tard.",
    "Public.success.free": "L'espace a été libéré",
    "Public.success.spaces": "La réservation de l'espace {{title}} de {{startHour}} à {{endHour}} a été annulée.",
    "Public.success.nowFree": "Cet espace est maintenant libre pour d’autres réservations.",
    "Public.keep.kept": "Votre réservation est conservée",
    "Public.keep.noAlert": "Vous ne recevrez plus d’alerte pour cette réservation.",
    "public.keep.spaces": "La réservation de l'espace {{title}} de {{startHour}} à {{endHour}} est conservée.",
    "Public.password.error": "Erreur lors de la création du mot de passe",
    "Public.forgotPassword.title": "Réinitialisation du mot de passe",
    "Public.forgotPassword.description": "Saisissez votre adresse email pour recevoir un lien de réinitialisation de votre mot de passe.",
    "Public.forgotPassword.login": "Adresse email",
    "Public.forgotPassword.submit": "Envoyer un mail de réinitialisation",
    "Public.forgotPassword.success": "Un email de réinitialisation de mot de passe vous a été envoyé.",
    "Public.forgotPassword.error": "Erreur lors de l'envoi de l'email de réinitialisation",

    //TRANSFERT
    "transfert.myDeparture": "Mon départ",
    "transfert.site": "Site de départ",
    "transfert.building": "Bâtiment",
    "transfert.boxes": "Dépôt des cartons",
    "transfert.move": "Déménagement",
    "transfert.myMoveIn": "Mon Emménagement",
    "transfert.moveIn": "Emménagement prévu",
    "transfert.zone": "Zone d’arrivée",
    "transfert.help": "Besoin d'aide ?",
    "transfert.question": "Une question sur votre déménagement ?",
    "transfert.contact": "Contacter votre correspondant qui pourra vous aider.",
    "transfert.myMove": "Mon déménagement",
    "transfert.welcome": "Bienvenue dans la fonctionnalité Transfert !",
    "transfert.findInfo": "Retrouvez ici toutes les informations liées à votre déménagement.",
    "transfert.understand": "J’ai compris",
    "transfert.noMove": "Vous n'avez aucun déménagement",
    "transfert.addAndUpdateFile": "Ajouter ou modifier un fichier",
    "transfert.addFile": "Ajouter un fichier",
    "transfert.error": "Erreur lors de l’ajout du fichier",
    "transfert.success": "Ajout du fichier réussi",
    "transfert.created": "Créé(s)",
    "transfert.updated": "Modifié(s)",
    "transfert.deleted": "Supprimé(s)",
    "transfert.row": "Ligne",
    "transfert.errors": "Erreur(s)",

    // CATERING
    "catering.prestationAvailable": "Prestation(s) disponible(s)",
    "catering.prestationNoAvailable": "Prestation(s) non disponible(s)",
    "catering.orderOutOfTime": "Votre commande est hors délai, il est trop tard pour réserver les prestations ci-dessous.",
    "catering.order": "Commander une prestation",
    "catering.newOrder": "Nouvelle demande",
    "catering.orderFor": "Commander pour le",
    "catering.prestation": "Mes prestations en cours",
    "catering.outlook": "Commandé depuis Outlook",
    "catering.newPresta": "Nouvelle prestation",
    "catering.thisSpace": "Choisir cet espace",
    "catering.addPresta": "Ajouter la prestation",
    "catering.saveOrder": "Enregistrer la commande",
    "catering.askSpace": "Dans quel espace souhaiteriez-vous commander une prestation ?",
    "catering.orderThe": "Commander le",
    "catering.addAPrestra": "Ajouter une prestation",
    "catering.selectRubrique": "Sélectionnez une rubrique",
    "Total": "Total",
    "catering.resume": "Résumé de la prestation",
    "catering.canceled": "Annuler la commande",
    "catering.sureCanceled": "Êtes-vous sûr de vouloir annuler la commande ?",
    "No": "Non",
    "catring.successUpdate": "Modification réussie",
    "catering.errorUpdate": "Erreur lors de la modification",
    "catering.update": "Modifier la commande",
    "catering.noOrder": "Vous ne pouvez plus modifier la commande",
    "amount": "Quantité",
    "obligatory": "Champ obligatoire",
    "canceled": "Annulé",
    "catering.request": "Demande",
    "catering.date": "Date :",

    // NEWS
    "BackTo": "Retour à {{topic}}",
    "Sure_Leave": "Vous êtes sur le point de quitter l'éditeur de contenu. Vous perdrez le travail en cours non publié. Êtes-vous sûr de vouloir continuer ?",
    "Yes_Leave": "Oui, quitter",
    "Select_A_Group": "Sélectionnez au moins un groupe pour pouvoir publier",
    "Content_Editing": "Edition de contenu",
    "Notif_Editing": "Edition de notification",
    "Publish": "Publier",
    "Update": "Mettre à jour",
    "Upload": "Ajouter",
    "Edit_Internationalized": "Sélectionnez la langue dans laquelle vous souhaitez éditer votre publication.",
    "Image_Library": "Bibliothèque d'image",
    "Add_Image": "Ajouter une image",
    "Select_Image": "Sélectionnez une image depuis la bibliothèque",
    "Title": "Titre",
    "Notif_Trigger": "Déclenchement de la notification",
    "Subtitle": "Sous-titre",
    "Choose_Title": "Choisissez un titre",
    "Choose_Subtitle": "Choisissez un sous-titre",
    "Publication": "Publication",
    "Content_To_Be_Shawn": "Le contenu sera visible dans l'application à partir du",
    "Hide_Content": "Cacher le contenu avant sa publication",
    "Send_Notif": "Envoyer une notification",
    "Access": "Limiter la visibilité de la publication",
    "Target": "Cibles",
    "Select_Group": "Pour limiter la visibilité de cette publication, sélectionnez les groupes d'utilisateurs autorisés à la consulter. (Par défaut, la publication sera visible par tous les groupes)",
    "Select_Group2": "Sélectionner les destinataires de la notification",
    "No_Group_Found": "Aucun groupe trouvé",
    "Topics": "Topics",
    "Select_Topics": "Sélectionner un ou plusieurs topics pour la news",
    "No_Topic": "Aucun topic disponible",
    "Attach_To_Place": "S'il s'agit d'un événement, vous pouvez ajouter un lieu à votre publication.",
    "Attach_To_Place2": "Rattacher ce contenu à un lieu",
    "Room": "Espace",
    "No_Stage_Found": "Aucun étage trouvé",
    "Select_A_Room": "Sélectionnez un espace",
    "Select_A_Stage": "Sélectionnez un étage",
    "No_Room_Found": "Aucun espace trouvé",
    "Name": "Nom",
    "Enter_Name_Place": "Entrez le nom du lieu",
    "Publication_Preview": "Prévisualisation dans l'app",
    "Add_News": "Ajouter une news",
    "Add_Notif": "Créer une notification",
    "Add_Topic": "Ajouter un topic",
    "Update_Topic": "Modifier un topic",
    "Manage_Topic": "Gérer les topics",
    "Trendings": "À la une",
    "Published_On": "Publié le",
    "Created_On": "Créé le",
    "Header_Image": "Image d'en-tête",
    "At": "à",
    "Get_details": "Récupération des détails de la section...",
    "Get_Content": "Récupération des contenus...",
    "Get_Content_Error": "Echec lors de la récupération des détails de la section...",
    "Get_Groups": "Récupération des groupes Swizi...",
    "Get_Groups_Error": "Echec lors de la récupération des groupes",
    "Get_Topics_Error": "Echec lors de la récupération des topics",
    "Get_Media": "Récupération des médias Swizi...",
    "Get_Media_Error": "Echec lors de la récupération des groupes Swizi",
    "Get_Resource": "Récupération des ressources Swizi..",
    "Get_Resource_Error": "Echec lors de la récupération des ressources Swizi.",
    "Show_HTML": "Passer en mode HTML",
    "Show_Editor": "Passer en mode editeur",
    "Format_Code": "Formater le code HTML",
    "ShowMore": "Voir plus >",
    "Published": "Publié {{date}}",
    "PublishedAt": "Publié le {{date}}",
    "News": "Actualités",
    "InTheSameTopic": "Dans la même rubrique",
    "Event": "Événement",
    "Event_description": "Définissez un événement pour l'afficher dans la news",
    "Event_startDate": "Début",
    "Event_endDate": "Fin",
    "Event_date_place": "Date et lieu de l'évènement",
    "Event_from": "Le",
    "Event_to": "au",
    "noFloorSelect": "Aucun étage sélectionné",

    // NEWS V2
    "news.read.article": "Lire l'article",
    "news.spotlight": "À la une",
    "news.all.article": "Consulter tous les articles",
    "news.event.title": "Date et lieu de l'évènement",
    "news.event.agence": "Agence de",
    "news.article.like": "J'aime cet article",
    "news.article.share": "Partager cet article",
    "news.article.same": "Articles similaires",
    "news.comments": "Commentaires",
    "news.comment.write": "Ecrivez votre commentaire ici",
    "news.comment.send": "Envoyer",
    "news.editor.confirm.title": "Supprimer la news",
    "news.editor.cnfirm.content": "Êtes-vous sûr de vouloir supprimer la news",
    "news.editor.new": "Nouvelle publication",
    "news.editor.new.topic": "Gérer les topics",
    "news.edior.all.topics": "Tous les topics",
    "news.editor.published.topics": " Publié dans",
    "news.editor.update": "Modifier",
    "news.editor.hide": "Masquer",
    "news.editor.noHide": "Démasquer",
    "news.editor.delete": "Supprimer",
    "news.editor.publish": "Publier",
    "news.editor.hide.title": "Masquer la publication",
    "news.editor.noHide.title": "Démasquer la publication",
    "news.editor.hide.content": "Cette publication sera masquée et ne sera plus visible par les utilisateurs. Son contenu ne sera pas supprimé.",
    "news.editor.noHide.content": "Cette publication sera démasquée et visible par les utilisateurs",
    "news.editor.hide.success": "News masquée",
    "news.editor.noHide.success": "News démasquée",
    "news.editor.hide.error": "La news n'a pas été caché",
    "news.editor.topics.create.success": "Création du topic réussie",
    "news.editor.topics.create.error": "Echec creation topic",
    "news.editor.topics.update.success": "Modification du topic réussie",
    "news.editor.topics.update.error": "Echec modification topic",
    "news.editor.topics.delete": "Topic supprimé",
    "news.editor.topics.use": "Déjà utilisé",
    "news.editor.topics.add": "Ajouter un topic",
    "news.editor.topics.new": "Nouveau topic",
    "news.editor.topics.update": "Modifier le topic",
    "news.editor.topics.create": "Créer le topic",
    "news.editor.topics.list": "Liste des topics",
    "news.editor.topics.name": "Nom du topic",
    "news.editor.update.success": "Modification réussie",
    "news.editor.update.error": "Echec des modifications",
    "news.editor.create.success": "Publication réussie",
    "news.editor.create.error": "Echec de la publication",
    "news.editor.create.error.pictures": "Veuillez ajouter une image d'en tête",
    "news.editor.web": "Web",
    "news.editor.mobile": "Mobile",
    "news.editor.title": "Cliquez pour ajouter un titre",
    "news.editor.subtitle": "Cliquez pour ajouter un sous-titre",
    "news.editor.header": "Cliquez pour ajouter une image d’entête",
    "news.editor.content": "Cliquez pour saisir le corps de la publication",
    "news.editor.options.languages": "Sélectionnez la langue et éditez le contenu de cette publication sur la partie gauche de l’écran.",
    "news.editor.options.topics": "Sélectionnez un ou plusieurs topics dans lesquels ajouter la publication.",
    "news.editor.options.notification.title": "Envoyer une notification",
    "news.editor.options.notification.content": "Sélectionnez cette option pour que les utilisateurs recoivent une notification au moment de la mise en ligne de cette publication.",
    "news.editor.options.publication.title": "Programmer la publication",
    "news.editor.options.publication.content": "Sélectionnez cette option et indiquez la date et l’heure à laquelle cette publication sera mise en ligne automatiquement.",
    "news.editor.options.hide.title": "Masquer automatiquement",
    "news.editor.options.hide.content": "Sélectionnez cette option et indiquez la date et l’heure à laquelle cette publication sera masquée aux utilisateurs.",
    "news.editor.options.event.title": "Ajouter un événement",
    "news.editor.options.event.placeholder": "Ajouter un nom à l'événement",
    "news.editor.options.event.content": "S’il s’agit d’un événement, vous pouvez ajouter un lieu à votre publication.",
    "news.editor.options.event.name": "Nom de l'événement",
    "news.editor.options.event.start": "Début de l'événement",
    "news.editor.options.event.end": "Fin de l'événement",
    "news.editor.options.event.localisation": "Localisation",
    "news.editor.options.event.building": "Bâtiment",
    "news.editor.options.event.outBuilding": "Hors bâtiment",
    "news.editor.options.event.findAdress": "Rechercher une adresse",
    "news.editor.options.groups.title": "Limiter la visibilité",
    "news.editor.options.groups.content": "Pour limiter la visibilité de cette publication, sélectionnez les groupes d’utilisateurs autorisés à la consulter. (Par défaut, la publication sera visible par tous les groupes).",
    "news.editor.options.groups.select": "Sélectionnez un groupe",
    "news.editor.options.comments.title": "Autoriser les commentaires",
    "news.editor.options.comments.content": "Sélectionnez cette option pour autoriser les utilisateurs à ajouter un commentaire à la publication.",
    "news.editor.iframe.title": "Integration HTML",
    "news.editor.iframe.content": "Copiez le code HTML du site que vous voulez intégrer.",
    "news.editor.iframe.placeholder": "Insérez le code HTML à intégrer ici",
    "news.editor.library.import.error": "erreur lors de l'ajout d'une image",
    "news.editor.library.title": "Inserer une image",
    "news.editor.library.validation": "Sélectionner cette image",
    "news.editor.library.content": "Sélectionnez une image existante dans la bibliothèque ci-dessous ou importez une nouvelle image depuis votre ordinateur.",
    "news.editor.library.format": "Formats pris en charge : png, jpeg Dimensions recommandées pour le header : 1220x450",
    "news.editor.library.import": "Importer",
    "news.editor.pictures.title": "Intégrer une balise image",
    "news.editor.library.import.modal.title": "Confirmer l'import ?",
    "news.editor.library.import.modal.p1": "L’import de votre image va commencer.",
    "news.editor.library.import.modal.p2": "Elle sera compressée et disponible dans quelques instants.",
    "news.editor.library.import.success": "Import réussi",
    "news.details.start.event": "À partir du",
    "news.details.end.event": "jusqu'au",
    "news.detail.noNews": "Aucune publication pour le moment, revenez plus tard",
    "news.editor.find": "Rechercher une news",
    "news.editor.library.search": "Rechercher une image",

    // TRANSPORT
    "transport.title": "Transports en commun",
    "transport.subtitle": "Sélectionnez un arrêt",
    "transport.segmented.allStop": "Tous les arrêts",
    "transport.segmented.fav": "Mes favoris",
    "transport_no_favorite": "Parcourez les arrêts et ajoutez des lignes en favoris pour les retrouver ici.",
    "select_a_line": "Sélectionnez une ligne",
    "go_to": "Y aller",
    "traffic_info": "Info trafic",
    "all_lines": "Toutes les lignes",
    "favorite": "Favori",
    "next_departures": "Prochains départs",
    "no_next_departures": "Aucun prochain départ",
    "navitia_data": "Données fournies par Navitia",
    "transport_no_alerts": "Tout va bien, aucune alerte pour le moment.",
    "my_latest_research": "Mes dernières recherches",
    "itinerary_sub": "Indiquez un point de départ et d'arrivée",
    "walking": "à pied",
    "departure_from": "Départ de",
    "walk_to": "Marcher jusqu'à",
    "stop": "arrêts",

    // QRCODE
    "qrcode.title": "Faites en plus avec l'application mobile {{app}}",
    "qrcode.content": "Scannez ce QR Code pour télécharger l’application",
    // AROUNDME
    "aroundMe.select.categorie": "Sélectionnez une catégorie",
    "phone_number": "Téléphone",
    "web_site": "Site web",
    "around_me_search": "Rechercher un(e) {{cat}}",
    "restaurants": "Restaurants",
    "bars": "Bars",
    "hotels": "Hôtels",
    "banks": "Banques",
    "parking": "Parking",
    "restaurants_sing": "Restaurant",
    "bars_sing": "Bar",
    "hotels_sing": "Hôtel",
    "banks_sing": "Banque",
    "parking_sing": "Parking",
  },
  csv: trads,
  common: common,
};
