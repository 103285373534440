import React from "react";
import { Route, Switch } from "react-router-dom";
import CGU from "./Contract/CGU";
import Privacypolicy from "./Contract/privacyPolicy";
import Confirmation from "./Password/Confirmation";
import Error from "./Password/Error";
import ForgotPassword from "./Password/ForgotPassword";
import Password from "./Password/Password";
import ReleaseRoom from "./ReleasseRoom/ReleaseRoom";

const Public = () => {
  return (
    <Switch>
      <Route path="/public/releaseRoom" exact>
        <ReleaseRoom />
      </Route>
      <Route path="/public/password" exact>
        <Password />
      </Route>
      <Route path="/public/forgotPassword" exact>
        <ForgotPassword />
      </Route>
      <Route path="/public/password/confirmation" exact>
        <Confirmation />
      </Route>
      <Route path="/public/password/error" exact>
        <Error />
      </Route>
      <Route path="/public/cgu" exact>
        <CGU />
      </Route>
      <Route path="/public/privacyPolicy" exact>
        <Privacypolicy />
      </Route>
    </Switch>
  );
};

export default Public;
