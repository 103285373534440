import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Segmented,
  Select,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import Tile from "../../../../Common/Tile/Tile";
import Glyph from "../../../../Common/Glyph/Glyph";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import { useSelector } from "react-redux";
import moment from "moment";
import I18nInput from "../../../../Common/Inputs/I18nInputs/I18nInput";
import styles from "../../News.module.less";
import { useRef, useState } from "react";
import FormItem from "antd/es/form/FormItem";
import { useTranslation } from "react-i18next";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { isNil } from "ramda";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import EditorTopics from "../EditorTopics";

const { Option } = Select;

const OptionsEditor = ({ setLang, lang, setPlaceGoogle }) => {
  const { colors } = useDesignTokens();
  const { t, i18n } = useTranslation();
  const { sectionId } = useParams();

  const inputRef = useRef();

  const locales = useSelector((state) => state.userWS.userData.locales);
  const topics = useSelector((state) => state.newsWS.topics);
  const groups = useSelector((state) => state.groupsWS.groups);
  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);
  const item = useSelector((state) => state.newsWS.itemEditor);
  const userData = useSelector((state) => state.userWS.userData);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [value, setValue] = useState(
    isNil(item?.event?.place) || isNil(item?.event) ? "places" : "google",
  );

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    setPlaceGoogle({
      address: place?.formatted_address,
      title: locales?.reduce((acc, locale) => {
        return { ...acc, [locale.title]: place?.name };
      }, {}),
    });
  };

  return (
    <>
      <Card>
        <Tile
          style={{
            flexFlow: "column",
            alignItems: "baseline",
            height: "auto",
            padding: "10px",
            cursor: "default",
          }}
        >
          <div style={{ marginBottom: "8px" }}>
            <Glyph name="language" style={{ color: colors.grey_60, marginRight: "8px" }} />
            <Typography.Text strong>{t("Languages")}</Typography.Text>
          </div>

          <Typography.Text type="secondary" style={{ marginBottom: "8px" }}>
            {t("news.editor.options.languages")}
          </Typography.Text>
          <Segmented
            size="meddium"
            value={lang}
            options={[
              {
                label: <p style={{ margin: "0" }}>🇫🇷 {t("French")}</p>,
                value: "fr",
              },
              {
                label: <p style={{ margin: "0" }}>🇬🇧 {t("English")}</p>,
                value: "en",
              },
            ]}
            onChange={(value) => {
              setLang(value);
            }}
          />
        </Tile>
        <Divider />
        <Tile
          style={{
            flexFlow: "column",
            alignItems: "baseline",
            height: "auto",
            padding: "10px",
            cursor: "default",
          }}
        >
          <div style={{ marginBottom: "8px" }}>
            <Glyph name="category" style={{ color: colors.grey_60, marginRight: "8px" }} />
            <Typography.Text strong>Topics</Typography.Text>
          </div>

          <Typography.Text type="secondary" style={{ marginBottom: "8px" }}>
            {t("news.editor.options.topics")}
          </Typography.Text>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <div style={{ position: "relative", width: "90%" }} id="topics">
              <Form.Item
                // noStyle
                style={{ margin: "0" }}
                name={["topics"]}
                rules={[
                  {
                    required: true,
                    message: t("obligatory"),
                  },
                ]}
              >
                <Select
                  getPopupContainer={() => document.getElementById("topics")}
                  mode="multiple"
                  placeholder={t("None")}
                  maxTagCount="responsive"
                  style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}
                >
                  {topics?.map((topic, index) => (
                    <Option key={index} value={topic?.id}>
                      {topic?.label[i18n.language.split("-")[0]]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Button
              type="secondary"
              icon={<Glyph name="add" />}
              style={{ marginLeft: "8px" }}
              onClick={() => setIsModalOpen(true)}
            ></Button>
          </div>
        </Tile>
        <Divider />
        {!userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold && (
          <Tile
            style={{
              flexFlow: "column",
              alignItems: "baseline",
              height: "auto",
              padding: "10px",
              marginBottom: "8px",
              cursor: "default",
            }}
          >
            <div
              style={{
                marginBottom: "8px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Glyph name="notifications" style={{ color: colors.grey_60, marginRight: "8px" }} />
                <Typography.Text strong>
                  {t("news.editor.options.notification.title")}
                </Typography.Text>
              </div>
              <FormItem noStyle name={["sendNotification"]} valuePropName="checked">
                <Switch
                  disabled={userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold}
                />
              </FormItem>
            </div>

            <Typography.Text type="secondary" style={{ marginBottom: "8px" }}>
              {t("news.editor.options.notification.content")}
            </Typography.Text>
          </Tile>
        )}

        {!userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold && (
          <Tile
            style={{
              flexFlow: "column",
              alignItems: "baseline",
              height: "auto",
              padding: "10px",
              marginBottom: "8px",
              cursor: "default",
            }}
          >
            <div
              style={{
                marginBottom: "8px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Glyph name="schedule" style={{ color: colors.grey_60, marginRight: "8px" }} />
                <Typography.Text strong>
                  {t("news.editor.options.publication.title")}
                </Typography.Text>
              </div>
              <Form.Item noStyle name={["publication"]} valuePropName="checked">
                <Switch
                  disabled={userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold}
                />
              </Form.Item>
            </div>

            <Typography.Text type="secondary" style={{ marginBottom: "8px" }}>
              {t("news.editor.options.publication.content")}
            </Typography.Text>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) =>
                getFieldValue("publication") && (
                  <div style={{ display: "flex" }}>
                    <Form.Item noStyle name={["startPublication"]} trigger="onSelect">
                      <DatePicker
                        size="medium"
                        style={{ marginRight: "8px", width: "200px" }}
                        disabledDate={(date) => date.isBefore(moment(), "date")}
                      />
                    </Form.Item>
                    <Form.Item noStyle name={["startPublication"]} trigger="onSelect">
                      <TimePicker
                        format="HH:mm"
                        showNow={false}
                        size="medium"
                        style={{ width: "200px" }}
                      />
                    </Form.Item>
                  </div>
                )
              }
            </Form.Item>
          </Tile>
        )}

        {!userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold && (
          <>
            <Tile
              style={{
                flexFlow: "column",
                alignItems: "baseline",
                height: "auto",
                padding: "10px",
                marginBottom: "8px",
                cursor: "default",
              }}
              disabled={userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold}
            >
              <div
                style={{
                  marginBottom: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <Glyph
                    name="visibility_off"
                    style={{ color: colors.grey_60, marginRight: "8px" }}
                  />
                  <Typography.Text strong>{t("news.editor.options.hide.title")}</Typography.Text>
                </div>
                <Form.Item noStyle name={["stopPublication"]} valuePropName="checked">
                  <Switch
                    disabled={userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold}
                  />
                </Form.Item>
              </div>

              <Typography.Text type="secondary" style={{ marginBottom: "8px" }}>
                {t("news.editor.options.hide.content")}
              </Typography.Text>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue("stopPublication") && (
                    <div style={{ display: "flex" }}>
                      <Form.Item noStyle name={["endPublication"]} trigger="onSelect">
                        <DatePicker
                          size="medium"
                          style={{ marginRight: "8px", width: "200px" }}
                          disabledDate={(date) => date.isBefore(moment(), "date")}
                        />
                      </Form.Item>
                      <Form.Item noStyle name={["endPublication"]} trigger="onSelect">
                        <TimePicker
                          format="HH:mm"
                          showNow={false}
                          size="medium"
                          style={{ width: "200px" }}
                        />
                      </Form.Item>
                    </div>
                  )
                }
              </Form.Item>
            </Tile>
            <Divider />
          </>
        )}

        {!userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold && (
          <>
            <Tile
              style={{
                flexFlow: "column",
                alignItems: "baseline",
                height: "auto",
                padding: "10px",
                marginBottom: "8px",
                cursor: "default",
              }}
            >
              <div
                style={{
                  marginBottom: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <Glyph name="event" style={{ color: colors.grey_60, marginRight: "8px" }} />
                  <Typography.Text strong>{t("news.editor.options.event.title")}</Typography.Text>
                </div>
                <Form.Item noStyle name={["isEvent"]} valuePropName="checked">
                  <Switch
                    disabled={userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold}
                  />
                </Form.Item>
              </div>

              <Typography.Text type="secondary" style={{ marginBottom: "8px" }}>
                {t("news.editor.options.event.content")}
              </Typography.Text>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue("isEvent") && (
                    <>
                      <div style={{ width: "100%" }}>
                        <Typography.Text strong>
                          {t("news.editor.options.event.name")}
                        </Typography.Text>
                        <Form.Item noStyle name={["titleEvent"]}>
                          <I18nInput
                            placeholder={t("news.editor.options.event.placeholder")}
                            className={styles["input"]}
                            locale={lang}
                            style={{ margin: "8px 0", fontSize: "16px" }}
                          />
                        </Form.Item>
                      </div>
                      <Typography.Text strong style={{ marginTop: "8px" }}>
                        {t("news.editor.options.event.start")}
                      </Typography.Text>
                      <div style={{ display: "flex" }}>
                        <Form.Item noStyle name={["startEvent"]} trigger="onSelect">
                          <DatePicker
                            size="medium"
                            style={{ marginRight: "8px", width: "170px" }}
                            disabledDate={(date) => date.isBefore(moment(), "date")}
                          />
                        </Form.Item>
                        <Form.Item noStyle name={["startEvent"]} trigger="onSelect">
                          <TimePicker
                            format="HH:mm"
                            showNow={false}
                            size="medium"
                            style={{ width: "170px" }}
                          />
                        </Form.Item>
                      </div>
                      <Typography.Text strong style={{ marginTop: "8px" }}>
                        {t("news.editor.options.event.end")}
                      </Typography.Text>
                      <div style={{ display: "flex" }}>
                        <Form.Item noStyle name={["endEvent"]} trigger="onSelect">
                          <DatePicker
                            size="medium"
                            style={{ marginRight: "8px", width: "170px" }}
                            disabledDate={(date) => date.isBefore(moment(), "date")}
                          />
                        </Form.Item>
                        <Form.Item noStyle name={["endEvent"]} trigger="onSelect">
                          <TimePicker
                            format="HH:mm"
                            showNow={false}
                            size="medium"
                            style={{ width: "170px" }}
                          />
                        </Form.Item>
                      </div>
                      <div
                        style={{
                          marginTop: "16px",
                          display: "flex",
                          flexFlow: "column",
                          width: "100%",
                        }}
                        className={styles["places"]}
                      >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography.Text strong>
                            {t("news.editor.options.event.localisation")}
                          </Typography.Text>
                          <Segmented
                            size="medium"
                            value={value}
                            options={[
                              {
                                label: (
                                  <p style={{ margin: "0" }}>
                                    {t("news.editor.options.event.building")}
                                  </p>
                                ),
                                value: "places",
                              },
                              {
                                label: (
                                  <p style={{ margin: "0" }}>
                                    {t("news.editor.options.event.outBuilding")}
                                  </p>
                                ),
                                value: "google",
                              },
                            ]}
                            onChange={(value) => {
                              setValue(value);
                            }}
                          />
                        </div>
                        {value === "places" && (
                          <div style={{ position: "relative", width: "100%" }} id="localisation">
                            <Form.Item noStyle name={["localisation"]}>
                              <Select
                                getPopupContainer={() => document.getElementById("localisation")}
                                showSearch
                                optionFilterProp="children"
                                placeholder={t("SearchSpace")}
                                style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}
                              >
                                {mapData?.resources
                                  ?.filter((r) => r?.features?.searchable)
                                  .sort((a, b) => (a.title > b.title ? 1 : -1))
                                  ?.map((resource, index) => (
                                    <Option key={index} value={resource?.id}>
                                      {resource?.title}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                        )}
                        {value === "google" && (
                          <StandaloneSearchBox
                            onLoad={(ref) => (inputRef.current = ref)}
                            onPlacesChanged={handlePlaceChanged}
                          >
                            <input
                              type="text"
                              placeholder={t("news.editor.options.event.findAdress")}
                              defaultValue={item?.event?.place?.address}
                            ></input>
                          </StandaloneSearchBox>
                        )}
                      </div>
                    </>
                  )
                }
              </Form.Item>
            </Tile>
            <Divider />
          </>
        )}
        <Tile
          style={{
            flexFlow: "column",
            alignItems: "baseline",
            height: "auto",
            padding: "10px",
            cursor: "default",
          }}
        >
          <div style={{ marginBottom: "8px" }}>
            <Glyph name="groups" style={{ color: colors.grey_60, marginRight: "8px" }} />
            <Typography.Text strong>{t("news.editor.options.groups.title")}</Typography.Text>
          </div>

          <Typography.Text type="secondary" style={{ marginBottom: "8px" }}>
            {t("news.editor.options.groups.content")}
          </Typography.Text>
          <div style={{ position: "relative", width: "100%" }} id="groups">
            <Form.Item noStyle name={["groups"]}>
              <Select
                getPopupContainer={() => document.getElementById("groups")}
                mode="multiple"
                placeholder={t("news.editor.options.groups.select")}
                maxTagCount="responsive"
                style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}
              >
                {groups?.map((group, index) => (
                  <Option key={index} value={group?.id}>
                    {group?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Tile>
        <Divider />
        <Tile
          style={{
            flexFlow: "column",
            alignItems: "baseline",
            height: "auto",
            padding: "10px",
            marginBottom: "8px",
            cursor: "default",
            display: userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold
              ? "none"
              : "block",
          }}
        >
          <div
            style={{
              marginBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <Glyph name="comment" style={{ color: colors.grey_60, marginRight: "8px" }} />
              <Typography.Text strong>{t("news.editor.options.comments.title")}</Typography.Text>
            </div>
            <FormItem noStyle name={["authorizeComment"]} valuePropName="checked">
              <Switch
                disabled={userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold}
              />
            </FormItem>
          </div>

          <Typography.Text type="secondary" style={{ marginBottom: "8px" }}>
            {t("news.editor.options.comments.content")}
          </Typography.Text>
        </Tile>
      </Card>
      <EditorTopics isModalOpen={isModalOpen} onCancel={() => setIsModalOpen(false)} />
    </>
  );
};

export default OptionsEditor;
