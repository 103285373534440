import { Button, Card, Col, Form, Modal, Progress, Row, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../../hook/useDesignTokens";
import { setMakeBooking } from "../../../../services/redux/services/FastbookingWS";
import Glyph from "../../../Common/Glyph/Glyph";
import RoomDetails from "../../../Common/RoomDetails/RoomDetails";
import clsx from "clsx";
import styles from "../Fastbooking.module.less";

import { Link, useHistory } from "react-router-dom";

import { ReactComponent as Confirm } from "../../../../assets/svg/confirmation.svg";
import { ReactComponent as Error } from "../../../../assets/svg/error.svg";
// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const AnimatedProgress = ({ status, ready, percent, setPercent }) => {
  useEffect(() => {
    let interval;
    if (ready && percent < 100) {
      interval = setInterval(() => {
        setPercent(percent + 20);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [ready, percent, setPercent]);

  return (
    <Progress
      type="circle"
      percent={percent}
      style={{ display: "flex", justifyContent: "end" }}
      width={80}
      showInfo={percent >= 100}
      status={status === "error" ? "exception" : status === "error" ? "success" : "normal"}
    />
  );
};

let time;
const STEPS = {
  RESA: "RESA",
  CONFIRM: "CONFIRM",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};
const ModalDetails = ({ isVisible, onCancel, selectedRoom, startDate, bookingClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { colors } = useDesignTokens();
  const dispatch = useDispatch();
  const history = useHistory();

  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);

  const [during, setDuring] = useState(30);
  const [status, setStatus] = useState(null);
  const [step, setStep] = useState(STEPS.RESA);
  const [ready, setReady] = useState(false);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    form.resetFields();
    setStep(STEPS.RESA);
  }, [form, isVisible]);

  const handleSubmit = async () => {
    time = setTimeout(() => {
      dispatch(
        setMakeBooking({
          roomId: selectedRoom?.resources?.id,
          startDate: startDate ? moment(startDate).toISOString() : moment().toISOString(),
          duration: during,
        }),
      )
        .unwrap()
        .then(() => {
          setStatus("success");
          setStep(STEPS.SUCCESS);
          setPercent(0);
          setReady(false);
          logEvent(analytics, "fast_booking_book_confirmed");
        })
        .catch(() => {
          setStep(STEPS.ERROR);
          setStatus("error");
        });
    }, 6000);
  };

  return (
    <>
      <Modal
        closable={false}
        title={
          step === STEPS.RESA ? (
            <>
              <Typography.Title level={5}>{selectedRoom?.resources?.title}</Typography.Title>{" "}
              <Typography.Text strong type="secondary">
                {selectedRoom?.availableTime
                  ? selectedRoom?.availableTime >= 60
                    ? `${t("Available")} > 1h - `
                    : `${moment
                        .duration(
                          moment(selectedRoom?.room?.endDate).diff(moment(), "minutes"),
                          "minutes",
                        )
                        .minutes()}
                        mins - `
                  : null}
                {t("Floor")}{" "}
                {
                  campus?.mapData?.floors.find(
                    (floor) => floor.reference === selectedRoom?.resources?.floorReference,
                  )?.displayName
                }
              </Typography.Text>
            </>
          ) : step === STEPS.CONFIRM ? (
            <Typography.Title level={5}>{t("ReservationDetail")}</Typography.Title>
          ) : step === STEPS.SUCCESS ? (
            <Typography.Title level={5}>{t("BookingArea")}</Typography.Title>
          ) : (
            <Typography.Title level={5}>{t("ErrorOccured")}</Typography.Title>
          )
        }
        open={isVisible}
        onOk={() => {
          onCancel();
          setStatus(null);
          setPercent(0);
        }}
        onCancel={() => {
          onCancel();
          setStatus(null);
          setPercent(0);
        }}
        footer={[
          step === STEPS.RESA ? (
            <>
              <Button ghost block disabled={!during}>
                <Link
                  to={{
                    pathname: `/fastBookingV2/${selectedRoom?.resources?.map?.findAndOrder?.placeId}`,
                    state: { room: selectedRoom },
                  }}
                  exact="true"
                >
                  {t("spas.request.actions.map")}
                </Link>
              </Button>
              {selectedRoom?.resources?.features?.bookable && (
                <Button
                  type="primary"
                  block
                  key="submit"
                  disabled={!during}
                  onClick={() => {
                    form.submit();
                    setStep(STEPS.CONFIRM);
                    setReady(true);
                  }}
                >
                  {t("Book")}
                </Button>
              )}
            </>
          ) : step === STEPS.CONFIRM ? (
            <Button
              ghost
              style={{ width: "375px" }}
              onClick={() => {
                clearTimeout(time);
                setStep(STEPS.RESA);
                setReady(false);
                setPercent(0);
              }}
            >
              {t("cancel")}
            </Button>
          ) : (
            <>
              <Button
                ghost
                style={{ width: "375px" }}
                onClick={() => {
                  onCancel();
                  setStep(STEPS.RESA);
                  history.push("/fastBookingV2");
                  bookingClose();
                  setStatus(null);
                  setPercent(0);
                }}
              >
                {t("BackHome")}
              </Button>
              <Button
                type="primary"
                style={{ width: "375px" }}
                onClick={() => logEvent(analytics, "fast_booking_itinerary")}
              >
                <Link
                  to={{
                    pathname: "/indoorMapping/itinerary",
                    state: {
                      back: `/fastBookingV2/${selectedRoom?.resources?.map?.findAndOrder?.placeId}`,
                      selectedRoom: selectedRoom?.resources,
                    },
                  }}
                >
                  {t("SeeItinerary")}
                </Link>
              </Button>
            </>
          ),
        ]}
        className={clsx(styles.modal)}
      >
        <div
          style={{
            display: step === STEPS.RESA ? "block" : "none",
          }}
        >
          <RoomDetails room={selectedRoom?.resources || selectedRoom} withTitle={false} />
          {selectedRoom?.resources?.features?.bookable ? (
            <Form
              form={form}
              style={{ marginTop: "30px" }}
              initialValues={{
                startDate: moment(startDate),
              }}
              onFinish={handleSubmit}
            >
              <Row gutter={[10, 20]}>
                <Col span={24}>
                  <Card>
                    <Row>
                      <Col flex={"auto"}>
                        <Typography.Text strong>
                          <Glyph
                            name="event"
                            style={{ color: colors.secondary_base, marginRight: "8px" }}
                          />
                          {t("BookThe")} {moment(startDate).format("DD MMMM YYYY")}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card>
                    <Row>
                      <Col flex={"auto"}>
                        <Typography.Text strong>
                          <Glyph
                            name="schedule"
                            style={{ color: colors.secondary_base, marginRight: "8px" }}
                          />
                          {t("At")} {moment(startDate).format("HH:mm")}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card>
                    <Row>
                      <Col flex={"auto"}>
                        <Typography.Text strong>
                          <Glyph
                            name="timelapse"
                            style={{ color: colors.secondary_base, marginRight: "8px" }}
                          />
                          {t("When")}
                        </Typography.Text>
                      </Col>
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            shape="circle"
                            size="small"
                            style={{ border: "none" }}
                            icon={<Glyph name="remove_circle" />}
                            onClick={() => {
                              if (during !== 30) setDuring(during - 15);
                              logEvent(analytics, "fast_booking_booking_duration", {
                                duration: during,
                              });
                            }}
                          ></Button>
                          <Typography.Text>
                            {during < 60
                              ? `${during} mins`
                              : moment().startOf("day").add(during, "minutes").format("hh[h]mm")}
                          </Typography.Text>
                          <Button
                            shape="circle"
                            size="small"
                            style={{ border: "none" }}
                            icon={<Glyph name="add_circle" />}
                            onClick={() => {
                              if (during !== 240) setDuring(during + 15);
                              logEvent(analytics, "fast_booking_booking_duration", {
                                duration: during,
                              });
                            }}
                          ></Button>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: "36px",
              }}
            >
              <Typography.Text strong type="secondary">
                Cet espace est en libre-service il est accessible sans réservation
              </Typography.Text>
            </div>
          )}
        </div>
        <div
          style={{
            display: step === STEPS.CONFIRM ? "flex" : "none",
            flexFlow: "row-reverse",
            justifyContent: "space-between",
          }}
        >
          <AnimatedProgress
            status={status}
            ready={ready}
            setPercent={setPercent}
            percent={percent}
          />
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
            }}
          >
            <Typography.Paragraph
              strong
              style={{ fontSize: "24px", margin: "24px 0", opacity: "0" }}
              id={styles["first"]}
            >
              <Glyph
                name="check_circle"
                style={{
                  marginRight: "8px",
                  fontSize: "20px",
                  color: colors.success_light,
                  verticalAlign: "0px",
                }}
              />
              {selectedRoom?.resources?.title}
            </Typography.Paragraph>
            <Typography.Paragraph
              strong
              style={{ fontSize: "24px", margin: "24px 0", opacity: "0" }}
              id={styles["second"]}
            >
              <Glyph
                name="check_circle"
                style={{
                  marginRight: "8px",
                  fontSize: "20px",
                  color: colors.success_light,
                  verticalAlign: "0px",
                }}
              />
              {moment(filters.startDate).format("DD MMMM YYYY")}
            </Typography.Paragraph>
            <Typography.Paragraph
              strong
              style={{ fontSize: "24px", margin: "24px 0", opacity: "0" }}
              id={styles["third"]}
            >
              <Glyph
                name="check_circle"
                style={{
                  marginRight: "8px",
                  fontSize: "20px",
                  color: colors.success_light,
                  verticalAlign: "0px",
                }}
              />
              {filters.startDate
                ? moment(filters.startDate).format("HH:mm")
                : moment().format("HH:mm")}{" "}
              -{" "}
              {filters.startDate
                ? moment(filters.startDate).add(during, "minutes").format("HH:mm")
                : moment().add(during, "minutes").format("HH:mm")}
            </Typography.Paragraph>
          </div>
        </div>
        <div
          style={{
            display: step === STEPS.SUCCESS ? "flex" : "none",
            flexFlow: "column",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Confirm />
          <Typography.Paragraph strong style={{ marginTop: "35px", fontSize: "20px" }}>
            <Glyph
              name="check_circle"
              style={{
                fontSize: "18px",
                verticalAlign: "-1px",
                marginRight: "8px",
                fontWeight: "normal",
                color: colors.success_light,
              }}
            />
            {t("BookingArea")}
          </Typography.Paragraph>
          <Typography.Text strong style={{ color: colors.pending_light }}>
            {selectedRoom?.title}{" "}
            {filters.startDate
              ? moment(filters.startDate).format(`DD/MM/YYYY`)
              : moment().format(`DD/MM/YYYY`)}{" "}
            {t("From_")}{" "}
            {filters.startDate
              ? moment(filters.startDate).format(`HH:mm`)
              : moment().format(`HH:mm`)}{" "}
            {t("To")}{" "}
            {filters.startDate
              ? moment(filters.startDate).add(during, "minutes").format("HH:mm")
              : moment().add(during, "minutes").format("HH:mm")}
          </Typography.Text>
        </div>
        <div
          style={{
            display: step === STEPS.ERROR ? "flex" : "none",
            flexFlow: "column",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Error />
          <Typography.Paragraph strong style={{ marginTop: "35px", fontSize: "20px" }}>
            <Glyph
              name="cancel"
              style={{
                fontSize: "18px",
                verticalAlign: "-1px",
                marginRight: "8px",
                fontWeight: "normal",
                color: colors.error_light,
              }}
            />
            {t("BookingDontWork")}
          </Typography.Paragraph>
          <Typography.Text strong style={{ color: colors.pending_light }}>
            {t("TryAgain")}
          </Typography.Text>
        </div>
      </Modal>
    </>
  );
};

export default ModalDetails;
