import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import keycloak from "./keycloak";
import Home from "./views/Home/Home";
import Login from "./views/Login/Login";
import Public from "./views/Public/Public";
import { LoadScript } from "@react-google-maps/api";

const HomeSwitch = () => {
  const { keycloak } = useKeycloak();
  return keycloak.authenticated ? <Home /> : <Login />;
};

const Routes = () => {
  const [isReady, setIsReady] = useState(false);

  const eventLogger = (event, error) => {
    keycloak
      .updateToken(300)
      .then((response) => {
        //I want to update my existing Token
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const tokenLogger = (tokens) => {
    localStorage.setItem("auth_data_dwm", JSON.stringify(tokens));
    setIsReady(true);
  };

  return (
    <Switch>
      <Route path="/public">
        <Public />
      </Route>
      <Route path="/">
        <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={eventLogger}
          onTokens={tokenLogger}
          initOptions={{
            onLoad: "check-sso",
            flow: "standard",
            checkLoginIframe: false,
            redirectUri: `${process.env.REACT_APP_DWM_URL}/api/redirectAuth?appId=${process.env.REACT_APP_ID}&type=${process.env.REACT_APP_TYPE}`,
          }}
          loginOptions={{ scope: "offline_access" }}
        >
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_FIREBASE_API_KEY}
            libraries={["places"]}
          >
            {isReady && <HomeSwitch />}
          </LoadScript>
        </ReactKeycloakProvider>
      </Route>
    </Switch>
  );
};

export default Routes;
