import Logo from "@tokens/Assets/web/img/logo/01_standard.png";
import { Button, Form, Image, Input, message, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useQuery from "../../../hook/useQuery";
import DWMConnector from "../../../services/api/DWMConnector";
import Glyph from "../../Common/Glyph/Glyph";
import "./Password.less";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const query = useQuery();
  const _login = query.get("login");

  const [sending, setSending] = useState(false);

  const onFinish = (values) => {
    setSending(true);
    return DWMConnector.sendResetPasswordMail(values.login)
      .then(() => {
        message.success(t("Public.forgotPassword.success"));
      })
      .catch((error) => {
        console.error(error);
        message.error(t("Public.forgotPassword.error"));
      })
      .finally(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    form.setFieldsValue({ login: _login });
  }, [form, _login]);

  return (
    <div className="password__layout">
      <div className="password__bg">
        <Image src={Logo} style={{ height: "58px" }} preview={false} />
        <Typography.Title level={4} className="processing">
          {t("Public.forgotPassword.title")}
        </Typography.Title>
        <Typography.Text>{t("Public.forgotPassword.description")}</Typography.Text>

        <Form
          form={form}
          style={{ marginTop: "30px" }}
          onFinish={onFinish}
          initialValues={{ login: _login }}
        >
          <Typography.Text>{t("Public.forgotPassword.login")}</Typography.Text>
          <Form.Item name="login" rules={[{ required: true }]}>
            <Input placeholder={_login} disabled={sending} />
          </Form.Item>

          <Button
            style={{ margin: "auto", padding: "0 15px" }}
            type="primary"
            icon={<Glyph name="done" style={{ marginRight: "8px" }} />}
            htmlType="submit"
            disabled={sending}
            loading={sending}
          >
            {t("Public.forgotPassword.submit")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
